import React, { useRef, useState } from "react";
import styles from "./UploadImageWebViewModal.module.scss";
import closeIcon from "../../../../img/closeIcon.svg";
import { Button } from "../../../../components/Button/Button";
import uploadIcon from "../../../../img/tabletools/download.svg";

type Props = {
  handleClose: () => void;
  handleSave: (image: string) => void;
};

type ImgProps = {
  saveImg: (img: string) => void;
}

function IconUpload({ saveImg }: ImgProps) {
  const [img, setImg] = useState<any | null>(null);
  const inputRef = useRef<any>();

  function handleFileUpload(files: FileList | null) {
    if (files) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImg(reader.result as string);
        saveImg(reader.result!.toString());
      };

      reader.readAsDataURL(files[0]);
    } else {
      alert("Не удалось загрузить файл");
    }
  }

  function handleClick() {
    if (!img) {
      inputRef.current.click();
    } else {
      inputRef.current.value = null;
      setImg(null);
    }
  }

  return (
    <div
      className={styles.iconUpload}
      onClick={() => handleClick()}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => {
        e.preventDefault();
        handleFileUpload(e.dataTransfer.files);
      }}
    >
      <input
        ref={inputRef}
        className={styles.hidden}
        type="file"
        onChange={(e) => handleFileUpload(e.target?.files)}
      />
      <div
        className={`${styles.previewContainer} ${img ? styles.imageSet : ""}`}
      >
        {!img ? (
          <>
            <img
              src={uploadIcon}
              width={22}
              height={22}
              className={styles.img}
              alt="upload Icon"
            />
            <p className={styles.uploadText}>Превью</p>
          </>
        ) : (
          <img src={img} width={120} height={120} alt=" Icon"/>
        )}
      </div>
    </div>
  );
}

export const UploadImageWebViewModal = React.forwardRef(({
  handleSave,
  handleClose,
}: Props, _) => {
  const [image, setImage] = useState<string | null>(null);

  const handleSaveImg = (img: string) => {
    setImage(img);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleContainer}>
        <h2>Загрузите ваше изображение</h2>
        <img
          onClick={handleClose}
          src={closeIcon}
          alt="close"
          width={24}
          height={24}
        />
      </div>
      <p className={styles.text}>
        Загрузите нужный вам файл. Формат jpg, png Размер файла не больше 5 мб
      </p>
      <div className={styles.uploadContainer}>
        <span className={styles.item}>
          <IconUpload saveImg={handleSaveImg}/>
        </span>
      </div>
      <div className={styles.btnsContainer}>
        <span className={styles.btn}>
          <Button
            type="button"
            onClick={() => {
              handleSave(image!);
            }}
            disabled={!image}
          >Сохранить</Button>
        </span>
        <span className={styles.btn}>
          <Button
            onClick={handleClose}
          >Отмена</Button>
        </span>
      </div>
    </div>
  );
})