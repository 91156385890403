import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getFiltersWebViewThunk } from "../../../store/reducers/webViewReducer";
import {
  validateWebView,
  webViewFormInitialValues,
  webViewFormSubmitHandler,
  WebViewFormValidationSchema,
  WebViewFormValues
} from "./helpers/formikHelpersWebView";
import styles from "../../PwaForm/PwaForm.module.scss";
import { StyledLoader, StyledTab, StyledTabs } from "../../PwaForm/PwaForm.styled";
import { Loader } from "../../../components/Loader/Loader";
import { getWebViewNavigation, setPage } from "../../../store/reducers/webViewFormReducer";
import { getPwaGlobalData } from "../../../store/reducers/globalDataReducer";
import { TABSWEBVIEW } from "./tabsWebView";

import { getWebViewData } from "../../../api/webView";
import {
  CreatedWebViewSuccessModal
} from "../../../components/Modals/CreatedPWASuccessModal/CreatedWebVIewSuccessModal";
import axios from "axios";
import { convertImageToBase64 } from "../../../helpers/formatData";


export const WebViewForm = () => {
  const dispatch = useAppDispatch();
  const formikRef = useRef<FormikProps<WebViewFormValues>>(null);
  const [success, setSuccess] = useState(false);
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { currentPage, maxUnlockedPage } = useAppSelector(getWebViewNavigation);
  const [webViewDataWasLoaded, setWebViewDataWasLoaded] = useState(false);


  useEffect(() => {
    dispatch(setPage(0));
    dispatch(getPwaGlobalData());
  }, [dispatch]);

  useEffect(() => {
    if (location.pathname.startsWith('/edit-web-view') && params.webViewId && formikRef.current) {
      getWebViewData(Number(params.webViewId)).then(async (webViewData) => {

        let messageImage = webViewData.message_image;
        if (webViewData.message_image?.startsWith('http:')) {
          messageImage = messageImage!.replace('http:', 'https:');
        }

        const image = await axios.get(messageImage!, { responseType: 'blob' });
        webViewData.message_image = await convertImageToBase64(image.data as unknown as File);

        formikRef.current?.setFormikState({
          values: webViewData,
          errors: {},
          touched: {},
          isSubmitting: false,
          isValidating: false,
          submitCount: 0,
        });

        setWebViewDataWasLoaded(true);
      });
    }
  }, [location, params, formikRef, navigate]);

  useEffect(() => {
    if (success && location.pathname.startsWith('/edit-web-view')) {
      navigate('/web-view')
    }
  }, [location, navigate, success]);

  return (
      <>
        <Formik
          innerRef={formikRef}
          initialValues={webViewFormInitialValues}
          validationSchema={WebViewFormValidationSchema}
          validate={validateWebView}
          onSubmit={async (values, formikHelpers) => {
            await webViewFormSubmitHandler(
              values,
              formikHelpers,
              () => setSuccess(true),
              params.webViewId ? Number(params.webViewId) : undefined
            );
            await dispatch(getFiltersWebViewThunk());
          }}
        >{({ values }) => (
          <Form>
            <div className="titleContainer">
              <h2 className={styles.title}>{values.name}</h2>
            </div>
            <div className={styles.container}>
              <StyledTabs
                value={currentPage}
                onChange={(_, page: number) => dispatch(setPage(page))}
              >
                {[
                  "Название",
                  "Бот",
                  "Трекер",
                  "Клоака",
                  "Наполнение",
                ].map((title, index) => (
                  <StyledTab
                    key={`tab-${index}`}
                    label={title}
                    id={index.toString()}
                    disabled={location.pathname === '/create-web-view' && index > maxUnlockedPage}
                  />
                ))}
              </StyledTabs>
            </div>
            <div className={styles.content}>
              {location.pathname.startsWith('/edit-web-view') && !webViewDataWasLoaded
                ? <StyledLoader><Loader/></StyledLoader>
                : TABSWEBVIEW.map((Tab: () => ReactElement, index) => (
                  <div hidden={currentPage !== index} key={`page-${index}`}>
                    <Tab/>
                  </div>
                ))}
            </div>
          </Form>
        )}
        </Formik>
        {success && !location.pathname.startsWith('/edit-web-view') && <CreatedWebViewSuccessModal/>}
      </>

  );
};
