import styles from "../../../PwaForm/PWAName/PWAName.module.scss";
import FormInput from "../../../../components/FormInput";
import { useFormikContext } from "formik";
import { WebViewFormValues } from "../helpers/formikHelpersWebView";
import { WebViewPagesNavigation } from "../WebViewPagesNavigation/WebViewPagesNavigation";

export const WebViewBot = ()=> {
  const { values, errors } = useFormikContext<WebViewFormValues>();
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>

        <span className={styles.inputItem}>
          <label>Bot token</label>
          <FormInput
            name="bot_token"
            placeholder="Введите токен"
          />
        </span>

        <span className={styles.inputItem}>
          <label>Bot name</label>
          <FormInput
            name="bot_name"
            placeholder="Введите имя бота"
          />
        </span>

        <WebViewPagesNavigation nexButtonDisabled={
          !values.bot_name || !!errors.bot_name || !values.bot_token || !!errors.bot_token}/>
      </div>
    </div>
  )
}