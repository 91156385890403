export type SelectElement = { label: string, value: string };
export type SelectList = { label: string, value: string }[];

export enum Ordering {
  asc = "",
  desc = "-",
}

export enum ViewType {
  pwa = "pwa",
  webView = "webView"
}
