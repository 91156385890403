import React, { useRef } from "react";

import closeIcon from "../../../img/closeIcon.svg";
import celebrationIcon from "../../../img/celebration.svg";
import { useNavigate } from "react-router-dom";
import { Button } from "../../Button/Button";
import styles from "./CreatedPWASuccessModal.module.scss";
import { useCloseModal } from "../hooks";


export const CreatedWebViewSuccessModal = () => {
  const navigate = useNavigate();

  const modalRef = useRef<HTMLDivElement>(null);

  useCloseModal(modalRef, ()=> navigate('/'));

  return (
    <div className="modal-background">
      <div className={styles.wrapper} ref={modalRef}>
        <div className="modal-titleContainer">
          <div className="modal-title">Создание WebView</div>

          <img
            src={closeIcon}
            alt="close icon"
            className="modal-closeIcon"
            onClick={() => navigate('/web-view')}
          />
        </div>
        <div className={styles.contentContainer}>
          <img alt="success icon" src={celebrationIcon} className={styles.icon}/>
          <div className={styles.warningInfo}>
            Ваш WebView успешно создан, нажмите “Продолжить” что бы перейти к списку ваших WebView
          </div>
        </div>
        <div className="modal-bottomWarapper">
          <Button onClick={() => navigate('/web-view')}>Продолжить</Button>
        </div>
      </div>
    </div>
  )
}