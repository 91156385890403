import { useNavigate, useSearchParams } from "react-router-dom";
import React, {useMemo, useState } from "react";
import {useAppSelector } from "../../store/hooks";
import {
  getCountriesOptions,
  getPwaEnumsVerticalPreviewTemplate, getEnumsStatus
} from "../../store/reducers/globalDataReducer";
import { useDebounce } from "../../hooks/useDebounce";
import { params } from "../../api/helpers/constants";
import styles from "../../../src/components/MainPageTemplate/MainPageTemplate.module.scss";
import { Button } from "../Button/Button";
import filterIcon from "../../img/filterIcon.svg";
import { MultiSelect } from "../Select/MultiSelect";
import { Select } from "../Select/Select";
import { ResetFiltersButton } from "../ResetFiltersButton/ResetFiltersButton";
import { filterStatusList } from "../../helpers/lists";
import { Search } from "../Search/Search";
import { ParamsRowSelect } from "../Select/ParamsRowSelect";
import { ParamsPagination } from "../Pagination/ParamsPagination";
import { ActivatePWAModal } from "../Modals/ActivatePWAModal/ActivatePWAModal";
import { DeletePWAModal } from "../Modals/DeletePWAModal/DeletePWAModal";
import { SelectList, ViewType } from "../../types/global";
import { PwaTable } from "../MainTableTemplate/PwaTable";
import { WebViewTable } from "../MainTableTemplate/WebViewTable";
import { ActivateWebViewModal } from "../Modals/ActivatePWAModal/ActivateWebViewModal";
import { DeleteWebViewModal } from "../Modals/DeletePWAModal/DeleteWebViewModal";


type Props = {
  pageCount: number;
  checkedList: Set<number>;
  globalFilters: { list: SelectList; domain: SelectList; }
  viewType: ViewType,
  rowsPerPage: number,
}

export const MainPageTemplate: React.FC<Props> = (
  {
    pageCount,
    checkedList,
    globalFilters,
    viewType,
    rowsPerPage,
  }
) => {
  const navigate = useNavigate();

  const countriesList = useAppSelector(getCountriesOptions);
  const statusOptions = useAppSelector(getEnumsStatus);
  const verticalPreviewTemplateEnum = useAppSelector(getPwaEnumsVerticalPreviewTemplate);
  const [searchParams, setSearchParams] = useSearchParams();

  const checkedListSize =  checkedList.size;


  const [name, setName] = useDebounce((name) =>
    setSearchParams(_params => {
      if (name) {
        _params.set(params.search, name);
        _params.set(params.page, '1');
      } else if (!name) {
        _params.delete(params.search);
        _params.set(params.page, '1');
      }
      return _params;
    }),
    searchParams.get(params.search) || "",
  );


  const [showActivateModal, setShowActivateModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const countriesListOptions = useMemo(() => {
    return countriesList
      .map(country => ({
        label: country.name,
        value: country.name
      }));
  }, [countriesList]);

  function handleResetFilters() {
    setSearchParams(new URLSearchParams());
    setName("");

  }

  let placeholderName;
  if (viewType === "pwa") {
    placeholderName = "Выберите PWA"
  } else {
    placeholderName = "Выберите WebView"
  }
  const filteredStatusOptions = statusOptions.filter(status => status.value !== "archived" && status.value !== "error");

  const [
    selectedDomains,
    selectedCountries,
  ] = useMemo(() => {
    return [
      params.domain,
      params.country,
    ].map(
      paramName => searchParams.get(paramName)
        ?.split(',')
        .filter(value => value !== '')
        .map(value => ({ label: value, value })) || []
    );
  }, [searchParams]);


  return (
    <>
      <div className={styles.wrapper}>
        <div className="title-container">
          <h2>{viewType === "pwa" ? "PWA" : "WebView"}</h2>
          <Button
            onClick={() => navigate(viewType === "pwa" ? "/create" : "/create-web-view")}>
            {viewType === "pwa" ? "Создать PWA" : "Создать новый"}
          </Button>
        </div>
        <div className={styles.settingsBlock}>
          <div className={styles.filtersBlock}>
            <img src={filterIcon} width={28} height={28} alt="filter icon"/>
            <div className={styles.filterContainer}>
              <MultiSelect
                values={selectedDomains}
                options={globalFilters.domain}
                onChange={(values) => setSearchParams(_params => {
                  _params.set(params.domain, values.map(el => el.value).join(','));
                  _params.set(params.page, '1');
                  return _params;
                })}
                placeholder="Выберите домен"
              />
            </div>
            <div className={styles.filterContainer}>
              <Select
                value={searchParams.get(params.status)}
                placeholder="Статус"
                options={filteredStatusOptions}
                onSet={(value) => {
                  setSearchParams(_params => {
                    _params.set(params.status, value);
                    _params.set(params.page, '1');
                    return _params;
                  });
                }}
              />
            </div>
            {viewType === "pwa" &&
              <div className={styles.filterContainer}>
                <Select
                  value={searchParams.get(params.vertical)}
                  placeholder="Выберите вертикаль"
                  options={verticalPreviewTemplateEnum}
                  onSet={(value) => {
                    setSearchParams(_params => {
                      _params.set(params.vertical, value);
                      _params.set(params.page, '1');
                      return _params;
                    });
                  }}
                />
              </div>
            }
            <div className={styles.filterContainer}>
              <MultiSelect
                values={selectedCountries}
                options={countriesListOptions}
                onChange={(values) => setSearchParams(_params => {
                  _params.set(params.country, values.map(el => el.value).join(','));
                  _params.set(params.page, '1');
                  return _params;
                })}
                placeholder="Выберите страны"
              />
            </div>
            {(searchParams.get(params.vertical)
              || searchParams.get(params.status)
              || searchParams.get(params.domain)
              || searchParams.get(params.country)
            ) && <ResetFiltersButton onClick={handleResetFilters}/>}
          </div>

        </div>
        <div className={styles.tableSettings}>
          <div className={styles.rightSide}>
            <div className={styles.groupAction}>
              <Select
                isDisabled={checkedListSize === 0}
                placeholder={checkedListSize === 0 ? placeholderName : "Выберите действие"}
                options={filterStatusList}
                onSet={(value) => {
                  if (value === "Удалить") {
                    setDeleteModal(true);
                  }
                  if (value === "Оплатить") {
                    setShowActivateModal(true)
                  }
                }}
              />
            </div>
            <Search
              value={name} placeholder={viewType === "pwa" ? "Введите название PWA" : "Введите название WebView"}
              onChange={(newValue) => setName(newValue)}/>
          </div>
          <div><ParamsRowSelect/></div>
        </div>
        <div className={styles.tableContainer}>
          {viewType === 'pwa'
            ? <PwaTable />
            : <WebViewTable />
          }
          <ParamsPagination
            listLength={pageCount}
            defaultRowsPerPage={rowsPerPage}
          />
        </div>
      </div>

      {showActivateModal && (viewType === "pwa" ?
        <ActivatePWAModal hideModal={() => setShowActivateModal(false)}/> :
        <ActivateWebViewModal hideModal={() => setShowActivateModal(false)}/>)
      }

      {showDeleteModal && (viewType === "pwa" ?
          <DeletePWAModal hideModal={() => setDeleteModal(false)}/> :
          <DeleteWebViewModal hideModal={() => setDeleteModal(false)}/>
      ) }
    </>
  );

}