import { MainPageTemplate } from "../../../components/MainPageTemplate/MainPageTemplate";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ViewType } from "../../../types/global";
import {
  getCreatedWebViewList,
  DEFAULT_ROWS_PER_PAGE,
  selectNoWebView,
  getWebViewListData, getCheckedWebViewList, getWebViewFilters, getFiltersWebViewThunk
} from "../../../store/reducers/webViewReducer";
import { useSearchParams } from "react-router-dom";

export const WebViewMainPage = () => {
  const dispatch = useAppDispatch();
  const webViewList = useAppSelector(getCreatedWebViewList);
  const checkedWebViewList = useAppSelector(getCheckedWebViewList);
  const webViewFilters = useAppSelector(getWebViewFilters);


  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    dispatch(selectNoWebView());
    const promise = dispatch(getWebViewListData());

    return () => {
      promise.abort();
    }
  }, [dispatch, searchParams]);

  useEffect(() => {
    dispatch(getFiltersWebViewThunk());
  }, [dispatch]);



  return <>
    <MainPageTemplate
      pageCount={webViewList.count}
      checkedList={checkedWebViewList}
      globalFilters={webViewFilters}
      viewType={ViewType.webView}
      rowsPerPage={DEFAULT_ROWS_PER_PAGE}
    />
  </>
}