import React from "react";
import styles from "./PagePreview.module.scss";
import cameraIcon from "../../img/phone/dot.svg";
import wifiIcon from "../../img/phone/wifi.svg";
import connectionIcon from "../../img/phone/connection.svg";
import batteryIcon from "../../img/phone/battery.svg";
import backArrowIcon from "../../img/phone/backArrow.svg";
import searchIcon from "../../img/phone/search.svg";
import dotsIcon from "../../img/phone/dots.svg";
import iconPlaceholder from "../../img/phone/iconPlaceholder.svg";
import startIcon from "../../img/phone/star.svg";
import commentAvatarPlaceholder from "../../img/commentAvatarPlaceholder.svg";
import ratingStarFilled from "../../img/ratingStarFilled.svg";
import ratingStarEmpty from "../../img/ratingStarEmpty.svg";
import ratingStarHalfFilled from "../../img/phone/halfStar.svg"
import lineIcon from "../../img/phone/line.svg";
import ageIcon from "../../img/phone/everyone.svg";
import previewImg from "../../img/gallery.svg";
import likeIcon from '../../img/likeIcon.svg';
import { useFormikContext } from "formik";
import { PwaFormValues } from "../../pages/PwaForm/helpers/formikHelperPWA";
import { useAppSelector } from "../../store/hooks";
import { getSelectedPreviewTemplate } from "../../store/reducers/pwaFormReducer";
import { PreviewRatingSlider } from "./PreviewRatingSlider/PreviewRatingSlider";
import replyCone from "../../img/replyTop.svg";
import dayjs from "dayjs";
import { convertDate } from "../../helpers/formatData";
import { NewPlayMarketContent } from "./NewPlayMarketContent/NewPlayMarketContent";

type Props = {
  contentRef?: React.MutableRefObject<any>,
}

function getStar(index: number, value?: number) {
  const ratingValue = Math.round((value || 0) * 2) / 2;
  const starNumber = index + 1;

  if (ratingValue >= starNumber) return ratingStarFilled;
  if (ratingValue < starNumber - 0.5) return ratingStarEmpty;
  return ratingStarHalfFilled;
}

export const PagePreview: React.FC<Props> = ({ contentRef }) => {
  const { values } = useFormikContext<PwaFormValues>();
  const selectedPreviewTemplate = useAppSelector(getSelectedPreviewTemplate);

  const str = values.application.description;
  const formattedTextArea = str?.split('\n');

  return (
    <div className={styles.wrapper}>
      <div className={styles.outerBorder}>
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            <img
              className={styles.camera}
              src={cameraIcon}
              width={18}
              height={18}
              alt="camera icon"
            />
            <p className={styles.text}>
              {new Date().getHours()} : {new Date().getMinutes()}
            </p>
          </div>
          <div className={styles.headerRight}>
            <img src={wifiIcon} alt="wifi icon"/>
            <img src={connectionIcon} alt="connection icon"/>
            <img src={batteryIcon} alt="batterry icon"/>
          </div>
        </div>

        <div ref={contentRef} className={styles.scrollContainer}>
          {values.vertical!.template === "old_play_market" ? <>
            <div className={styles.iconsRow}>
              <div className={styles.leftSide}>
                <img src={backArrowIcon} width={24} height={24} alt="back arrow icon"/>
              </div>
              <div className={styles.rightSide}>
                <img
                  className={styles.searchIcon}
                  src={searchIcon}
                  width={24}
                  height={24}
                  alt="search icon"
                />
                <img src={dotsIcon} width={24} height={24} alt="dots icon"/>
              </div>
            </div>
            <div className={styles.iconContainer}>
              <div className={styles.icon}>
                <img
                  src={selectedPreviewTemplate?.icon || iconPlaceholder}
                  width={56}
                  height={56}
                  alt="icon palceholder"
                />
              </div>
              <div className="col-s">
                <h4 className={styles.iconTitle}>{values.application.name}</h4>
                <div>
                  <span className={styles.iconSubtitle}>{values.application.author}</span>
                  <span className={styles.iconSubtitle}>{values.application.category}</span>
                </div>
              </div>
            </div>
            <div className={styles.infoContainer}>
              <div className={styles.reviews}>
                <div className={styles.reviews_top}>
                  <p>{(values.application.rating || 0).toFixed(1)}</p>
                  <img src={startIcon} width={22} height={22} alt="start icon"/>
                </div>
                <div className={styles.text}>{values.application.rating_text}</div>
              </div>
              <img src={lineIcon} width={2} height={32} alt="line icon"/>
              <div className={styles.downloads}>
                <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" width="20">
                  <g transform="translate(21.552 22.5) rotate(180)">
                    <path transform="translate(7.552 7.652)"
                          d="M.625,0h8.75A.68.68,0,0,1,10,.723a.68.68,0,0,1-.625.723H.625A.68.68,0,0,1,0,.723.68.68,0,0,1,.625,0Z"
                          data-name="Path 288"></path>
                    <g transform="translate(17.552 20.797) rotate(180)">
                      <path d="M0,0H9.666V9.666H0Z" fill="none" data-name="Path 289"></path>
                      <path transform="translate(-4.408 -3.203)"
                            d="M8.752,4.642V11.81L5.536,8.678a.677.677,0,0,0-.936,0,.627.627,0,0,0,0,.9l4.343,4.229a.669.669,0,0,0,.929,0l4.343-4.229a.627.627,0,0,0,0-.9.669.669,0,0,0-.929,0L10.07,11.81V4.642a.659.659,0,0,0-1.318,0Z"
                            data-name="Path 290"></path>
                    </g>
                    <rect transform="translate(4.552 5.5)" width="16" height="16" rx="2" fill="none" stroke="#000"
                          strokeWidth="2" data-name="Rectangle 123"></rect>
                  </g>
                </svg>
                <div className={styles.text}>{values.application.size}</div>
              </div>
              <img src={lineIcon} width={2} height={32} alt="line icon"/>
              <div className={styles.age}>
                <img src={ageIcon} width={15} height={15} alt="age icon"/>
                <p>{values.application.age}</p>
              </div>
              <img src={lineIcon} width={2} height={32} alt="line icon"/>
              <div className={styles.downloads}>
                <p className={styles.count}>{values.application.installs_count}</p>
                <p className={styles.text}>{values.application.installs}</p>
              </div>

            </div>
            <button type="button" className={styles.installBtn}>{values.application.install}</button>
            <div className={styles.galleryContainer}>
              {[
                selectedPreviewTemplate?.screenshot_1,
                selectedPreviewTemplate?.screenshot_2,
                selectedPreviewTemplate?.screenshot_3,
                selectedPreviewTemplate?.screenshot_4
              ].map((image, index) => <img
                key={`screenshot-${index}`}
                src={image || previewImg}
                className={styles.galleryItem} alt="gallery"
                style={!image ? { objectFit: "none" } : {}}
              />)}
            </div>
            <div className={styles.aboutAppContainer}>
              <div className={styles.titleRow}>
                <h4>{values.application.about}</h4>
              </div>

              <div className={styles.description}>
                {formattedTextArea && formattedTextArea.map(paragraph => <p
                  key={paragraph + 20 * Math.random()}>{paragraph}</p>)}
              </div>
              <div className={styles.readMore}>{values.application.read_more}</div>
              <div className={styles.ratingBlock}>
                <div className={styles.titleContainer}>
                  <h3>{values.application.reviews}</h3>
                </div>

                <div className={styles.info}>
                  <div className={styles.leftSide}>
                    <p className={styles.bigNumb}>
                      {(values.application.rating || 0).toFixed(1)}
                    </p>
                    <div>{
                      (new Array(5)).fill(null).map((_, index) => (
                        <img
                          key={`preview_rating_star_${index}`}
                          width={13}
                          src={getStar(index, values.application.rating)}
                          alt="rating star icon"
                        />
                      ))
                    }
                      <div className={styles.reviewsCount}>{values.application.reviews_count} K</div>
                    </div>
                  </div>
                  <div className={styles.rightSide}>
                    {[
                      values.application.five_star,
                      values.application.four_star,
                      values.application.three_star,
                      values.application.two_star,
                      values.application.one_star,
                    ].map((value, index) => <div
                      key={`star_slider_${index}`}
                      className={styles.sliderRow}
                    >
                      <p>{5 - index}</p>
                      <PreviewRatingSlider value={value}/>
                    </div>)}
                  </div>
                </div>
              </div>
            </div>
            {values.comments.map((comment, index) => {
              return (
                <div key={index}>
                  <div className={styles.commentContainer}>
                    <img
                      className={styles.commentAvatar}
                      src={comment.avatar || commentAvatarPlaceholder}
                      alt="user avatar"
                    />
                    <div>
                      <div className={styles.commentHeader}>
                        <div>
                          <div className={styles.commentName}>{comment.author}</div>
                          <div className={styles.commentDate}>{comment.date}</div>
                        </div>
                        <div>{
                          (new Array(5)).fill(null).map((_, index) => (
                            <img
                              key={`preview_rating_star__${index}`}
                              width={10}
                              src={getStar(index, comment.rating)}
                              alt="rating star icon"
                            />
                          ))
                        }</div>
                        <div className={styles.likesContainer}>
                          <img src={likeIcon} alt="like" width={24} height={24}/>
                          <div className={styles.likesCount}>{comment.likes}</div>
                        </div>
                      </div>
                      <div className={styles.commentText}>
                        {comment.text}
                      </div>
                    </div>
                    {comment.reply &&
                      <div>
                        <img src={replyCone} alt="reply" className={styles.cone}/>
                        <div className={styles.reply}>
                          <div className={styles.replyTop}>
                            <div className={styles.replyAuthor}>{values.application.author}</div>
                            <div
                              className={styles.replyDate}>{comment.reply?.date || convertDate(dayjs(new Date()).toString())}</div>
                          </div>
                          <div className={styles.replayText}>{comment.reply?.text}</div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              );
            })}
            <div className={styles.whatsNew}>
              <div className={styles.whatsNew_text}>{values.application.whats_new_text}</div>
              <div className={styles.whatsNew_bonus}>{values.application.whats_new}</div>
            </div>
            <div className={styles.additionalInfo}>
              <div className={styles.additionalInfo_title}>{values.application.additional_information}</div>
              <div className={styles.additionalContainer}>
                <div>
                  <div className={styles.characterization}>
                    <div className={styles.characterization_topText}>{values.application.updated}</div>
                    <div className={styles.characterization_bottomText}>{values.application.update_date}</div>
                  </div>

                  <div className={styles.characterization}>
                    <div className={styles.characterization_topText}>{values.application.installs}</div>
                    <div className={styles.characterization_bottomText}>{values.application.installs_count}</div>
                  </div>

                  <div className={styles.characterization}>
                    <div className={styles.characterization_topText}>{values.application.age_text}</div>
                    <div className={styles.characterization_bottomText}>{values.application.age}</div>
                  </div>

                </div>
                <div className={styles.characterization}>
                  <div className={styles.characterization_topText}>{values.application.size_text}</div>
                  <div className={styles.characterization_bottomText}>{values.application.size}Mb</div>

                  <div className={styles.characterization}>
                    <div className={styles.characterization_topText}>{values.application.current_version_text}</div>
                    <div className={styles.characterization_bottomText}>{values.application.current_version}</div>
                  </div>

                  <div className={styles.characterization}>
                    <div className={styles.characterization_topText}>{values.application.developer}</div>
                    <div className={styles.characterization_bottomText}>{values.application.author}</div>
                  </div>
                </div>
                <div>
                </div>
              </div>

            </div>
          </> : <NewPlayMarketContent/>}
        </div>
      </div>
    </div>
  );
};

