import { useMemo } from "react";
import { useFormikContext } from "formik";

import styles from "./ListOfErrors.module.scss";
import { PwaFormValues } from "../helpers/formikHelperPWA";


export const ListOfErrors = () => {
  const { errors } = useFormikContext<PwaFormValues>();

  const errorsSummary: string[] = useMemo(() => {
    const res: string[] = [];

    if (errors.name) {
      res.push("Название");
    }
    if (errors.cloak) {
      res.push("Клоака");
    }
    if (errors.tracker) {
      res.push("Трекер");
    }
    if (errors.comments) {
      res.push("Комментарии");
    }
    if (errors.vertical) {
      res.push("Дизайн");
    }

    return res;
  }, [errors]);

  return (
    <div className={styles.serverErrorsContainer}>
        {errorsSummary.length !== 0 && <div className={styles.serverErrors}>
            Исправьте ошибки на вкладках: {errorsSummary.join(', ')}
        </div>}
        {errors.serverError && <div className={styles.serverErrors}>
            Ошибка на сервере: {errors.serverError.toString()}
        </div>}
    </div>
  );
};
