import styles from "../../../PwaForm/Tracker/Tracker.module.scss";
import { FormInput } from "../../../../components/FormInput/FormInput";
import React from "react";
import { useFormikContext } from "formik";
import { WebViewFormValues } from "../helpers/formikHelpersWebView";
import { WebViewPagesNavigation } from "../WebViewPagesNavigation/WebViewPagesNavigation";

export const WebViewTracker = ()=> {
  const { values, errors } = useFormikContext<WebViewFormValues>();
  return (
    <div className={styles.wrapper}>
      <div className={styles.column}>
        <div className={styles.columnsContainer}>
          <div className={styles.column}>
            <div className={styles.row}>
              <div className={styles.inputContainer}>
                <label>Ссылка на оффер</label>
                <FormInput
                  name="offer_url"
                  placeholder="Введите ссылку"
                />
                <div className={styles.info}> Обязательно передайте значение &#123;tds_clickid&#125; в одном из своих
                  параметров
                </div>
              </div>
            </div>
          </div>
        </div>
        <WebViewPagesNavigation
          nexButtonDisabled={!values.offer_url || !!errors.offer_url}
        />
      </div>
    </div>
  )
}