import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Form, Formik, FormikProps } from "formik";

import styles from "./PwaForm.module.scss";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getPreviewTemplateById, getPwaFormData, getPwaNavigation, setPage } from "../../store/reducers/pwaFormReducer";
import {
  formatFormData,
  PwaFormValues,
  pwaFormInitialValues,
  pwaFormSubmitHandler,
  PwaFormValidationSchema, validate,
} from "./helpers/formikHelperPWA";
import { TABS } from "./data";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getFiltersThunk, getPwaGlobalData } from "../../store/reducers/globalDataReducer";
import { getPwaData } from "../../api/pwa";
import { CreatedPWASuccessModal } from "../../components/Modals/CreatedPWASuccessModal/CreatedPWASuccessModal";
import { StyledLoader, StyledTab, StyledTabs } from "./PwaForm.styled";
import { Loader } from "../../components/Loader/Loader";
import { SnackbarProvider } from "notistack";
import {PendingPWAModal} from "../../components/Modals/PendingPWAModal/PendingPWAModal";


export const PwaForm = () => {
  const dispatch = useAppDispatch();
  const { currentPage, maxUnlockedPage } = useAppSelector(getPwaNavigation);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const formikRef = useRef<FormikProps<PwaFormValues>>(null);
  const [success, setSuccess] = useState(false);
  const [domain, setDomain] = useState("");
  const [pwaDataWasLoaded, setPwaDataWasLoaded] = useState(false);
  const [createPwaPending, setCreatePwaPending] = useState(false)

  useEffect(() => {
    dispatch(setPage(0));
    dispatch(getPwaFormData());
    dispatch(getPwaGlobalData());
  }, [dispatch]);

  const delaySetCreatePwaPending = (bool: boolean) => {
    if (bool === false) {
      console.log('worked here')
      setTimeout(() => {
        setCreatePwaPending(bool);
        setSuccess(true)
      }, 3000);
    } else {
      setCreatePwaPending(bool)
    }
  }

  useEffect(() => {
    if (location.pathname.startsWith('/edit') && params.pwaId && formikRef.current) {
      getPwaData(Number(params.pwaId)).then(async (pwaData) => {
        if (pwaData.domain) setDomain(pwaData.domain);

        const newFormData: PwaFormValues = await formatFormData(pwaData);

        formikRef.current?.setFormikState({
          values: newFormData,
          errors: {},
          touched: {},
          isSubmitting: false,
          isValidating: false,
          submitCount: 0,
        });

        if (newFormData.vertical?.preview_template) {
          dispatch(getPreviewTemplateById(newFormData.vertical.preview_template));
        }
        setPwaDataWasLoaded(true);
      });
    }
  }, [location, params, formikRef, navigate]);

  const successUpdateData = () => {
    navigate('/')
  }

  return (
    <>
      <SnackbarProvider
        maxSnack={10}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <div className={styles.wrapper}>
          <Formik
            innerRef={formikRef}
            initialValues={pwaFormInitialValues}
            validationSchema={PwaFormValidationSchema}
            validate={validate}
            onSubmit={async (values, formikHelpers) => {
              await pwaFormSubmitHandler(
                  values,
                  formikHelpers,
                  setCreatePwaPending,
                  delaySetCreatePwaPending,
                  successUpdateData,
                  params.pwaId ? Number(params.pwaId) : undefined,
              );
              await dispatch(getFiltersThunk());
            }}
          >{({ values }) => (
            <Form>
              <div className="titleContainer">
                <h2 className={styles.title}>{values.name} {domain && "| " + domain}</h2>
              </div>
              <div className={styles.container}>
                <StyledTabs
                  value={currentPage}
                  onChange={(_, page: number) => dispatch(setPage(page))}
                >
                  {[
                    "Название",
                    "Трекер",
                    "Клоака",
                    "Дизайн",
                    "Наполнение",
                    "Комментарии",
                  ].map((title, index) => (
                    <StyledTab
                      key={`tab-${index}`}
                      label={title}
                      id={index.toString()}
                      disabled={location.pathname === '/create' && index > maxUnlockedPage}
                    />
                  ))}
                </StyledTabs>
              </div>
              <div className={styles.content}>
                {location.pathname.startsWith('/edit') && !pwaDataWasLoaded
                  ? <StyledLoader><Loader/></StyledLoader>
                  : TABS.map((Tab: () => ReactElement, index) => (
                    <div hidden={currentPage !== index} key={`page-${index}`}>
                      <Tab/>
                    </div>
                  ))}
              </div>
            </Form>
          )}
          </Formik>
        </div>
        {createPwaPending && <PendingPWAModal active={createPwaPending} setActive={setCreatePwaPending}/>}
        {success && !location.pathname.startsWith('/edit') && <CreatedPWASuccessModal/>}
      </SnackbarProvider>
    </>
  );
};
