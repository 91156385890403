import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import styles from "./Comment.module.scss";
import { FormInput } from "../FormInput/FormInput";
import { useFormikContext } from "formik";
import { PwaFormValues } from "../../pages/PwaForm/helpers/formikHelperPWA";
import { convertDate } from "../../helpers/formatData";
import UploadAvatar from "../UploadAvatar/UploadAvatar";
import deleteIcon from "../../img/deleteBtn.svg"
import addIcon from "../../img/addIcon.svg";
import { FormDatePicker } from "../DatePicker/FormDatePicker";
import { GenerateCommentModal } from "../Modals/GenerateCommentsModal/GenerateCommentsModal";

type CommentCreateProps = {
  commentId: number;
  removeHandler: () => void;
};

const TOUCH_DELAY = 5_000; // 5 seconds


export const Comment: React.FC<CommentCreateProps> = ({ commentId, removeHandler }) => {
  const { setFieldValue, setFieldTouched, values } = useFormikContext<PwaFormValues>();



  useEffect(() => {
    setTimeout(async () => {
      await setFieldTouched(`comments.${commentId}.author`, true);
      await setFieldTouched(`comments.${commentId}.text`, true);
    }, TOUCH_DELAY);
  }, [commentId, setFieldTouched]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.columnsContainer}>
          <div className={styles.column}>

            <div className={styles.commentsContainer}>
              <div className={styles.topRowContainer}>
                <UploadAvatar
                  text="Аватар"
                  fieldName={`comments.${commentId}.avatar`}
                  img={values.comments[commentId].avatar}
                />
                <div className={`${styles.inputContainer}`}>
                  <label className={styles.label}>Автор</label>
                  <FormInput
                    name={`comments.${commentId}.author`}
                    placeholder="Введите текст"
                  />
                </div>
                <div className={`${styles.inputContainer}`}>
                  <label className={styles.label}>Рейтинг</label>
                  <FormInput
                    type="number"
                    name={`comments.${commentId}.rating`}
                    placeholder="Введите текст"
                    min="0"
                    max="5"
                  />
                </div>
                <div className={`${styles.inputContainer}`}>
                  <label className={styles.label}>Лайки</label>
                  <FormInput
                    type="number"
                    name={`comments.${commentId}.likes`}
                    placeholder="Введите текст"
                    min="0"
                  />
                </div>
              </div>
              <div className={styles.rowContainer}>
                <div className={`${styles.inputContainer}`}>
                  <label className={styles.label}>Дата</label>
                  <FormDatePicker
                    name={`comments.${commentId}.date`}
                    value={
                      dayjs(values.comments[commentId]?.date)
                        ? dayjs(values.comments[commentId].date)
                        : dayjs(new Date())
                    }
                    className={styles.datePicker}
                    defaultValue={dayjs(new Date())}
                  />
                </div>

                <div className={`${styles.inputContainer}`}>
                  <label className={styles.label}>Текст комментария</label>
                  <FormInput
                    type="text"
                    name={`comments.${commentId}.text`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {!values.comments[commentId].reply && <button
            type="button" className={styles.addAnswer}
            onClick={() => setFieldValue(`comments.${commentId}.reply`, {
              date: convertDate((new Date()).toString()),
              author: "Author"
            })}
        >
            <>Добавить ответ</>
            <img src={addIcon} alt="add icon"/>
        </button>}
        {values.comments[commentId].reply && <div className={styles.answerContainer}>
            <h4 className={styles.title}>Ответ</h4>
            <div className={styles.rowContainer}>
                <div className={`${styles.inputContainer}`}>
                    <label className={styles.label}>Дата</label>
                    <FormDatePicker
                        name={`comments.${commentId}.reply.date`}
                        value={
                          values.comments[commentId]?.reply?.date
                            ? dayjs(values.comments[commentId].reply.date)
                            : dayjs(new Date())
                        }
                        className={styles.datePicker}
                        defaultValue={dayjs(new Date())}
                    />
                </div>
            </div>
            <div className={`${styles.inputContainer}  ${styles.answerTextContainer}`}>
                <label className={styles.label}>Текст ответа</label>
                <FormInput
                    name={`comments.${commentId}.reply.text`}
                    placeholder="Введите текст"
                />
            </div>
            <button
                type="button"
                className={styles.deleteAnswerBtn}
                onClick={() => setFieldValue(`comments.${commentId}.reply`, null)}
            >
                <>Удалить ответ</>
                <img src={deleteIcon} alt="bin" className={styles.deleteAnswerIcon}/>
            </button>

        </div>
        }
        <div className={styles.btnsContainer}>
          <button type="button" onClick={removeHandler} className={styles.deleteComment}>Удалить</button>
        </div>
      </div>


    </div>
  );
};
