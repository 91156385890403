import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getCloaksOptions, getCountries, getPwaOptions } from "../../api/pwa";
import { SelectList } from "../../types/global";
import { getWalletBalance } from "../../api/finance";
import { Country } from "../../types/api";
import { getDomainFilterList, getPwaFilterList } from "../../api/statistics";


type InitialState = {
  serverEnums: {
    verticalPreviewTemplateType: SelectList,
    verticalTemplate: SelectList,
    verticalVertical: SelectList,
    status: SelectList,
    cloakStatus: SelectList,
    cloakTypes: SelectList,
    cloakWhitePageType: SelectList,
    wordPressThemeOptions: SelectList,
  },
  filters: {
    list: SelectList,
    domain: SelectList,
  },
  countriesOptions: Country[],
  walletBalance: number | string,
}

const initialState: InitialState = {
  serverEnums: {
    verticalPreviewTemplateType: [],
    verticalTemplate: [],
    verticalVertical: [],
    status: [],
    cloakStatus: [],
    cloakTypes: [],
    cloakWhitePageType: [],
    wordPressThemeOptions: [],
  },
  filters: {
    list: [],
    domain: [],
  },
  countriesOptions: [],
  walletBalance: "loading...",
};

export const getPwaGlobalData = createAsyncThunk(
  'globalDataReducer/getPwaGlobalData',
  async () => {
    const [
      pwaOptionsData,
      walletBalance,
      cloaksOptions,
      countriesData,
    ] = await Promise.all([
      getPwaOptions(),
      getWalletBalance(),
      getCloaksOptions(),
      getCountries(),
    ]);

    return {
      pwaOptionsData,
      walletBalance,
      cloaksOptions,
      countriesData,
    };
  },
);

export const updateWalletBalance = createAsyncThunk(
  'globalDataReducer/updateWalletBalance',
  async () => {
    return await getWalletBalance();
  },
);

export const getFiltersThunk = createAsyncThunk(
  'statisticsReducer/getFiltersThunk',
  async () => {
    const [
      domainFilterList,
      pwaFilterList,
    ] = await Promise.all([
      getDomainFilterList(),
      getPwaFilterList(),
    ]);

    return {
      domainFilterList,
      pwaFilterList,
    };
  },
);

const globalDataReducer = createSlice({
  name: "globalDataReducer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPwaGlobalData.fulfilled, (state, action) => {
      // enums
      state.serverEnums.status = action.payload.pwaOptionsData.actions.POST.status.choices
        .map(({ value, display_name }) => ({ value, label: display_name }));
      state.serverEnums.verticalPreviewTemplateType = action.payload.pwaOptionsData.actions.POST.vertical.children
        .preview_template.children.template_type.choices
        .map(({ value, display_name }) => ({ value, label: display_name }));
      state.serverEnums.verticalTemplate = action.payload.pwaOptionsData.actions.POST.vertical.children
        .template.choices
        .map(({ value, display_name }) => ({ value, label: display_name }));
      state.serverEnums.verticalVertical = action.payload.pwaOptionsData.actions.POST.vertical.children
        .vertical.choices
        .map(({ value, display_name }) => ({ value, label: display_name }));

      //cloak
      state.serverEnums.cloakStatus = action.payload.cloaksOptions.actions.POST.status.choices
        .map(({ value, display_name }) => ({ value, label: display_name }));
      state.serverEnums.cloakTypes = action.payload.cloaksOptions.actions.POST.type.choices
        .map(({ value, display_name }) => ({ value, label: display_name }));
      state.serverEnums.cloakWhitePageType = action.payload.cloaksOptions.actions.POST.whitepage_type.choices
        .map(({ value, display_name }) => ({ value, label: display_name }));
      state.serverEnums.wordPressThemeOptions = action.payload.cloaksOptions.actions.POST.wordpress_theme.choices
        .map(({ value, display_name }) => ({ value, label: display_name }));

      state.walletBalance = action.payload.walletBalance.user_balance;

      state.countriesOptions = action.payload.countriesData;
    });

    builder.addCase(updateWalletBalance.fulfilled, (state, action)=> {
      state.walletBalance = action.payload.user_balance;
    });

    builder.addCase(getFiltersThunk.fulfilled, (state, action) => {
      state.filters.domain = action.payload.domainFilterList.result
        .map(({ address }) => ({ label: address, value: address }));
      state.filters.list = action.payload.pwaFilterList.result
        .map(({ name }) => ({ label: name, value: name }));
    });
  }
});


export const getPwaEnumsVerticalPreviewTemplate = (state: RootState) => state.globalDataReducer.serverEnums.verticalPreviewTemplateType;
export const getPwaEnumsVerticalTemplate = (state: RootState) => state.globalDataReducer.serverEnums.verticalTemplate;
export const getEnumsStatus = (state: RootState) => state.globalDataReducer.serverEnums.status;
export const getCloakEnumWhitePageType = (state: RootState) => state.globalDataReducer.serverEnums.cloakWhitePageType;
export const getCloakEnumsStatus = (state: RootState) => state.globalDataReducer.serverEnums.cloakStatus;
export const getCloakEnumsTypes = (state: RootState) => state.globalDataReducer.serverEnums.cloakTypes;

export const getWordPressEnumsTheme = (state: RootState) => state.globalDataReducer.serverEnums.wordPressThemeOptions;

export const getCountriesOptions = (state: RootState) => state.globalDataReducer.countriesOptions;

export const getWalletBalanceAmount = (state: RootState) => state.globalDataReducer.walletBalance;
export const getGlobalsFilters = (state: RootState) => state.globalDataReducer.filters;


export default globalDataReducer.reducer;