import { useMemo } from "react";
import { useFormikContext } from "formik";
import styles from "../../PwaForm/ListOfErrors/ListOfErrors.module.scss";
import { WebViewFormValues } from "./helpers/formikHelpersWebView";


export const ListOfWebViewFormErrors = () => {
  const { errors } = useFormikContext<WebViewFormValues>();

  const errorsSummary: string[] = useMemo(() => {
    const res: string[] = [];
    if (errors.name) {
      res.push("Название");
    }
    if (errors.cloak) {
      res.push("Клоака");
    }
    if (errors.offer_url) {
      res.push("Трекер");
    }
    if (errors.bot_token) {
      res.push("Бот");
    }
    return res;
  }, [errors]);

  return (
    <div className={styles.serverErrorsContainer}>
      {errorsSummary.length !== 0 && <div className={styles.serverErrors}>
          Исправьте ошибки на вкладках: {errorsSummary.join(', ')}
          </div>}
  {errors.serverError && <div className={styles.serverErrors}>
    Ошибка на сервере: {errors.serverError.toString()}
    </div>}
    </div>

  );
};
