import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getCheckedPWAList, getCreatedPWAList, getPwaListData, selectNoPWA, DEFAULT_ROWS_PER_PAGE, changeSelectedPWAsStatus
} from "../../store/reducers/pwaListReducer";

import {
  getFiltersThunk,
  getGlobalsFilters,

} from "../../store/reducers/globalDataReducer";
import { MainPageTemplate } from "../../components/MainPageTemplate/MainPageTemplate";
import { ViewType } from "../../types/global";
import { useSearchParams } from "react-router-dom";


export const PwaMainPage = () => {
  const dispatch = useAppDispatch();

  const pwaList = useAppSelector(getCreatedPWAList);
  const checkedPwaList = useAppSelector(getCheckedPWAList);
  const globalFilters = useAppSelector(getGlobalsFilters);

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    dispatch(getFiltersThunk());
  }, [dispatch]);


  useEffect(() => {
    dispatch(selectNoPWA());
    const promise = dispatch(getPwaListData());

    return () => {
      promise.abort();
    }
  }, [dispatch, searchParams]);

return (
  <MainPageTemplate
    pageCount={pwaList.count}
    checkedList={checkedPwaList}
    globalFilters={globalFilters}
    viewType={ViewType.pwa}
    rowsPerPage={DEFAULT_ROWS_PER_PAGE}
  />
)

};
