import React, { useEffect, useRef, useState } from "react";
import closeIcon from "../../../img/closeIcon.svg";
import { Button } from "../../Button/Button";
import styles from "./DescriptionLibraryModal.module.scss";
import { useCloseModal } from "../hooks";
import { SelectList } from "../../../types/global";
import { useFormikContext } from "formik";
import { PwaFormValues } from "../../../pages/PwaForm/helpers/formikHelperPWA";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getAllDescriptions,
  getAllDescriptionsThunk,
  getDescriptionByIdThunk,
  getSelectedDescription,
  getTranslatedDescription,
  getDescriptionTranslationThunk,
  resetSelectedDescription, getTranslationStatus
} from "../../../store/reducers/localizationReducer";
import { Select } from "../../Select/Select";
import { Loader } from "../../Loader/Loader";

type Props = {
  hideModal: () => void;
  languages: SelectList;
};

export const DescriptionLibraryModal: React.FC<Props> = ({ hideModal, languages }) => {
  const dispatch = useAppDispatch();
  const allDescriptionsList = useAppSelector(getAllDescriptions);

  const modalRef = useRef<HTMLDivElement>(null);
  const { setFieldValue } = useFormikContext<PwaFormValues>();
  const [expandDescriptionIndex, setExpandDescriptionIndex] = useState(-1);

  useEffect(() => {
    dispatch(getAllDescriptionsThunk());

    return () => {
      dispatch(resetSelectedDescription());
    }
  }, [dispatch]);

  const selectedDescription = useAppSelector(getSelectedDescription);
  const translatedDescription = useAppSelector(getTranslatedDescription);
  const translationStatus = useAppSelector(getTranslationStatus);


  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>("");


  const [modalText, setModalText] = useState("");

  useEffect(() => {
    setModalText(selectedDescription.description);

    if (selectedLanguage) {
      dispatch(getDescriptionByIdThunk(selectedDescription.id))
      setModalText(translatedDescription.translated_description);
    }
  }, [selectedDescription, selectedLanguage]);


  useCloseModal(modalRef, hideModal);

  return (
    <div className="modal-background">
      <div className={styles.wrapper} ref={modalRef}>
        <div className="modal-titleContainer">
          <div className="modal-title">Библиотека описаний</div>
          <img
            src={closeIcon}
            alt="close icon"
            className="modal-closeIcon"
            onClick={hideModal}
          />
        </div>
        <div className={styles.tableModalContainer}>
          <div className={styles.tableHeader}>
            <div>Описание</div>
            <div>Локализация</div>
          </div>
          <div className={styles.table}>
            <div className={styles.table_leftSide}>
              {allDescriptionsList.map((description, index) =>
                <div
                  key={description.id}
                  className={`${styles.table_leftSide_content} ${expandDescriptionIndex === index && styles.table_leftSide_content_opened}`}
                  onClick={() => {
                    setExpandDescriptionIndex(prev => {
                      if (index === prev) return -1;
                      return index;
                    });
                    dispatch(getDescriptionByIdThunk(description.id))
                    setSelectedLanguage(undefined);
                  }}
                >
                  {description.description}
                </div>)
              }
            </div>
            <div className={styles.table_rightSide}>
              <div>Здесь вы можете перевести описание на выбранный язык, после нажатия кнопки “Подтвердить” оно
                автоматически отобразиться в полях на языке локализацьии.
              </div>
              <div className={styles.table_rightSide_info}>*действе не обязательное</div>

              <label className={styles.label}> Локализация (не обязательно)</label>
              <Select
                options={languages}
                isSearchable
                placeholder="Выберите язык"
                value={selectedLanguage}
                onChange={(e) => {
                  const newLanguage = e?.value;
                  setSelectedLanguage(newLanguage);

                  dispatch(getDescriptionTranslationThunk({
                    id: selectedDescription.id,
                    language_id: Number(newLanguage)
                  }))
                }}
              />
              {translationStatus === "pending"
                ? <div className={styles.translationWindow}><Loader/></div>
                : <textarea
                  value={modalText}
                  onChange={(e) => setModalText(e.target.value)}
                  className={styles.translationWindow}/>}
            </div>
          </div>
        </div>
        <div className="modal-bottomWarapper">
          <Button
            onClick={async () => {
              await setFieldValue("application.description", modalText)
              hideModal();
            }}
          >
            Подтвердить
          </Button>
        </div>
      </div>
    </div>
  )
}