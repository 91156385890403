import React from "react";
import styles from "../../../PwaForm/PagesNavigation/PagesNavigation.module.scss";
import { useLocation } from "react-router-dom";
import {Button} from "../../../../components/Button/Button";
import { useFormikContext } from "formik";
import { getWebViewNavigation } from "../../../../store/reducers/webViewFormReducer";
import { WebViewFormValues } from "../helpers/formikHelpersWebView";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { MAX_PAGE_NUMBER } from "../tabsWebView";
import { goToPrevPage, goToNextPage } from "../../../../store/reducers/webViewFormReducer";
import { ListOfWebViewFormErrors } from "../ListOfWebViewFormErrors";


type Props = {
  nexButtonDisabled: boolean
}

export const WebViewPagesNavigation: React.FC<Props> = ({
  nexButtonDisabled,
}) => {
  const dispatch = useAppDispatch();
  const { currentPage } = useAppSelector(getWebViewNavigation);
  const location = useLocation();
  const { values, errors } = useFormikContext<WebViewFormValues>();

  const editPage = location.pathname.startsWith('/edit-web-view');
  const submitButton = currentPage === MAX_PAGE_NUMBER || editPage;

  return (
    <>
      <div className={styles.wrapper}>
        {submitButton && !!values.name && <ListOfWebViewFormErrors/>}
        {currentPage !== 0 && <Button
          onClick={() => dispatch(goToPrevPage())}
          appear="outline"
        >Назад</Button>}
        <Button
          type={submitButton ? 'submit' : 'button'}
          onClick={(e) => {
            if (!submitButton) {
              e.preventDefault();
              dispatch(goToNextPage());
            }
          }}
          disabled={nexButtonDisabled || (editPage && Object.keys(errors).length !== 0)}
        >{editPage ? 'Сохранить'
          : currentPage === MAX_PAGE_NUMBER ? 'Отправить' : 'Дальше'}</Button>
      </div>

    </>
  );
};
