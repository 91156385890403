import styles from "./Table.module.scss";
import { formatTimestamp } from "../../helpers/formatData";
import infoIcon from "../../img/info.svg";
import { useAppSelector } from "../../store/hooks";
import {
  DEFAULT_ROWS_PER_PAGE,
  getStatusOptions, getTransactions,
} from "../../store/reducers/financeReducer";
import React, { useEffect, useState } from "react";
import { InvoiceForPaymentModal } from "../Modals/InvoiceForPaymentModal/InvoiceForPaymentModal";
import { useSearchParams } from "react-router-dom";
import { ordering, params } from "../../api/helpers/constants";
import { TransactionPurpose } from "../../types/finance";
import { ParamsPagination } from "../Pagination/ParamsPagination";
import { ParamsSortIcon } from "../SortIcon/ParamsSortIcon";
import { Loader } from "../Loader/Loader";

export const RefillTable = () => {
  const transactions = useAppSelector(getTransactions);
  const statusOptions = useAppSelector(getStatusOptions);

  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchParams(_params => {
      _params.set(params.purpose, TransactionPurpose.refill);
      return _params;
    });
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(-1);

  return (
    <>
      <table className={`${styles.table} ${styles.billsTable}`}>
        <thead>
        <tr>
          <th>Статус<ParamsSortIcon sortsBy={ordering.status}/></th>
          <th>Cумма<ParamsSortIcon sortsBy={ordering.amount}/></th>
          <th>Создан<ParamsSortIcon sortsBy={ordering.created_at}/></th>
          <th>Действия</th>
        </tr>
        </thead>
        <tbody className={styles.tableContent}>
        {transactions.data.length > 0 ? transactions.data.map((tx, index) => (
          <tr key={index + "trow"}>
            <td
              className={
                `${tx.status === 'success' ? styles.success : ''} ` +
                `${tx.status === 'pending' ? styles.inProcess : ''} ` +
                `${tx.status === 'invalid' ? styles.notValid : ''}`
              }
            >{statusOptions[tx.status]} </td>
            <td className={(Number(tx.amount) < 0) ? styles.negative : ''}>$ {tx.amount}</td>
            <td>{formatTimestamp(tx.created_at)}</td>
            <td>
              <div
                className={styles.infoContainer}
                onClick={() => {
                  setShowModal(true);
                  setSelectedTransaction(tx.id)
                }}
              >
                <img src={infoIcon} alt="info icon"/>
                <button type="button">Инфо</button>
              </div>
              <div className={styles.infoModal}>
              {showModal && selectedTransaction === tx.id &&
                  <InvoiceForPaymentModal
                      hideModal={() => setShowModal(false)}
                      amount={tx.amount}
                      transactionId={tx.id}
                      status={tx.status}
                  />
              }
              </div>
            </td>
          </tr>
        )) : (
          <tr>
            <td className={styles.emptyState}>Пока нет записей</td>
          </tr>
        )}
        </tbody>
      </table>
      <ParamsPagination
        listLength={transactions.count}
        defaultRowsPerPage={DEFAULT_ROWS_PER_PAGE}
      />
    </>
  );
};
