import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Button } from "../../components/Button/Button";
import styles from "./StatisticsPage.module.scss";
import filterIcon from "../../img/filterIcon.svg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { GeneralStatisticTable } from "../../components/Tables/GeneralStatisticTable";
import { ExportReportModal } from "../../components/Modals/ExportReportModal/ExportReportModal";
import { getDownloadLink } from "../../api/statistics";
import { ResetFiltersButton } from "../../components/ResetFiltersButton/ResetFiltersButton";
import { MultiSelect } from "../../components/Select/MultiSelect";
import { params } from "../../api/helpers/constants";
import {
  getCountriesOptions,
  getFiltersThunk,
  getGlobalsFilters,
  getPwaGlobalData
} from "../../store/reducers/globalDataReducer";
import { ParamsRowSelect } from "../../components/Select/ParamsRowSelect";
import { DateSelect } from "../../components/Select/DateSelect";

export const GeneralStatisticsPage = () => {
  const dispatch = useAppDispatch();
  const filtersOptions = useAppSelector(getGlobalsFilters);
  const countriesList = useAppSelector(getCountriesOptions);

  const countriesListOptions = useMemo(() => {
    return countriesList
      .map(country => ({
        label: country.name,
        value: country.name
      }));
  }, [countriesList]);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    dispatch(getFiltersThunk());
   dispatch(getPwaGlobalData());
  }, [dispatch]);

  const [showModal, setShowModal] = useState(false);

  const location = useLocation();

  const [
    selectedPwas,
    selectedDomains,
    selectedCountries,
  ] = useMemo(() => {
    return [
      params.pwa,
      params.domain,
      params.country,
    ].map(
      paramName => searchParams.get(paramName)
        ?.split(',')
        .filter(value => value !== '')
        .map(value => ({ label: value, value })) || []
    );
  }, [searchParams]);

  return (
    <>
      <div className="title-container">
        <h2>Статистика{location.pathname === "/statistics" ? " общая" : " по кликам"}</h2>
        <Button onClick={() => {
          setShowModal(true)
        }}>Скачать отчет</Button>
      </div>
      <div className={`filters-panel ${styles.filtersBlock}`}>
        <img src={filterIcon} width={28} height={28} alt="filter icon"/>
        <div className={styles.filterContainer}>
          <MultiSelect
            options={filtersOptions.list}
            values={selectedPwas}
            onChange={(values) => setSearchParams(_params => {
              _params.set(params.pwa, values.map(el => el.value).join(','));
              return _params;
            })}
            placeholder="Выберите PWA"
          />
        </div>
        <div className={styles.filterContainer}>
          <MultiSelect
            options={filtersOptions.domain}
            values={selectedDomains}
            onChange={(values) => setSearchParams(_params => {
              _params.set(params.domain, values.map(el => el.value).join(','));
              return _params;
            })}
            placeholder="Выберите домен"
          />
        </div>
        <div className={styles.filterContainer}>
          <MultiSelect
            options={countriesListOptions}
            values={selectedCountries}
            onChange={(values) => setSearchParams(_params => {
              _params.set(params.country, values.map(el => el.value).join(','));
              return _params;
            })}
            placeholder="Выберите страну"
          />
        </div>
        <DateSelect />
        {(searchParams.get(params.pwa)
          || searchParams.get(params.domain)
          || searchParams.get(params.country)
          || searchParams.get(params.date_after)
          || searchParams.get(params.date_before)
        ) && <ResetFiltersButton onClick={() => setSearchParams('')}/>}
      </div>
      <div className={styles.rightSide}>
        <ParamsRowSelect/>
      </div>
      <GeneralStatisticTable/>
      {showModal && <ExportReportModal
          downloadLink={getDownloadLink(searchParams)}
          hideModal={() => setShowModal(false)}
          fileName="statistics"
      />}
    </>
  );
};
