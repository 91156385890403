import * as Yup from "yup";
import { FormikHelpers } from "formik";
import { formatErrors } from "../../../../helpers/globalFormikHelpers";
import { sendWebView, updateWebView } from "../../../../api/webView";
import { PwaDataGet } from "../../../../types/api";
import axios from "axios";
import { convertImageToBase64 } from "../../../../helpers/formatData";
import { pwaFormInitialValues, PwaFormValues } from "../../../PwaForm/helpers/formikHelperPWA";

export type WebViewFormValues = {
  name: string,
  bot_token: string,
  bot_name: string,
  offer_url: string,
  button: string,
  message: string,
  message_button: string,
  message_image?: string | undefined,
  cloak: {
    id?: number,
    white_url: string,
    status: string,
    type: string,
    countries: number[],
    white_ips: string[],
    whitepage_type?: string,
    wordpress_theme?: null,
  }
  serverError?: any,
}

export const webViewFormInitialValues: WebViewFormValues = {
  name: "",
  bot_token: "",
  bot_name: "",
  offer_url: "",
  button: "",
  message_button: "",
  message_image: "" || undefined,
  message: "",
  cloak: {
    white_url: "",
    status: "",
    type: "",
    countries: [],
    white_ips: [],
  }
}

export const webViewFormSubmitHandler = async (
  values: WebViewFormValues,
  formikHelpers: FormikHelpers<WebViewFormValues>,
  successCallback: () => void,
  id ?: number
) => {
  const cloak = { ...values.cloak };
  cloak.countries = [...new Set(cloak.countries)];

  const webViewData: WebViewFormValues = {
    name: values.name,
    cloak,
    bot_token: values.bot_token,
    bot_name: values.bot_name,
    offer_url: values.offer_url,
    button: values.button,
    message: values.message,
    message_image: values.message_image,
    message_button: values.message_button,
  }

  console.log({ webViewData });
  try {
    if (id) {
      await updateWebView(id, webViewData);
    } else {
      await sendWebView(webViewData);
    }
    successCallback();
  } catch (response: any) {
    const formattedErrors = formatErrors(response.response.data);
    if (Object.keys(formattedErrors).length !== 0) {
      formikHelpers.setErrors(formattedErrors);
    } else {
      formikHelpers.setErrors({ serverError: response.message } as any);
    }
  }
}

export const WebViewFormValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, 'Слишком короткое!')
    .max(400, 'Слишком длинное!')
    .required('Введите имя'),
  bot_name: Yup.string().required('Введите имя бота'),
  bot_token: Yup.string().required('Введите бот токен'),
  offer_url: Yup.string()
    .min(1, 'Слишком короткое!')
    .max(1000, 'Слишком длинное!')
    .matches(/^https:/, "Ссылка должна начинаться с https:")
    .matches(/\{tds_clickid}/, "Ссылка должна содержать {tds_clickid}")
    .required('Введите ссылку на оффер'),
  button: Yup.string().required('Введите кнопку'),
  message_button: Yup.string().required('Введите сообщение кнопки'),
});


export function validateWebView(values: WebViewFormValues) {
  const errors: any = {};

  if (
    values.cloak.whitepage_type === "gp"
    && !values.cloak.white_url?.includes("play.google.com/store/apps/details?id")
  ) {
    if (!errors.cloak) errors.cloak = {};
    errors.cloak.white_url = "Неправильная ссылка на приложение из Google Play";
  }

  if (
    values.cloak.whitepage_type === "ap"
    && !values.cloak.white_url?.includes("apps.apple.com/us/story/id")
  ) {
    if (!errors.cloak) errors.cloak = {};
    errors.cloak.white_url = "Неправильная ссылка на приложение из App Store";
  }

  if ((values.cloak.whitepage_type === "gp" || values.cloak.whitepage_type === "ap")
    && !values.cloak.white_url
  ) {
    errors.cloak.white_url = "Введите ссылку на вайт";
  }
  return errors;
}
