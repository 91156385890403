import styles from "./UploadAvatar.module.scss";
import uploadIcon from "../../img/tabletools/download.svg";
import React, { useRef } from "react";
import { useFormikContext } from "formik";
import { PwaFormValues } from "../../pages/PwaForm/helpers/formikHelperPWA";

type Props = {
  text: string;
  fieldName: string;
  img?: string;
};

function UploadAvatar({ text, fieldName, img }: Props) {
  const { setFieldValue } = useFormikContext<PwaFormValues>();
  const inputRef = useRef<HTMLInputElement | null>(null);

  function handleFileUpload(files: FileList | null) {
    if (files && files?.length > 0) {
      const reader = new FileReader();
      const fileBlob = new Blob([files[0]], { type: files[0].type });
      reader.onloadend = () => {
        setFieldValue(fieldName, reader.result!.toString());
      };
      reader.readAsDataURL(fileBlob);
    } else {
      alert("Не удалось загрузить файл");
    }
  }

  const handleClick = () => {
    inputRef?.current?.click();
  };

  return (

    <div
      className={styles.avatarContainer}
      onClick={() => handleClick()}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => {
        e.preventDefault();
        handleFileUpload(e.dataTransfer.files);
      }}
    >
      <input
        ref={inputRef}
        className={styles.hidden}
        type="file"
        onChange={(e) => handleFileUpload(e.target?.files)}
      />
      {
        img ? <div className={styles.selectedImg}>
            <img src={img} alt="Icon"/>
        </div>
          : <>
            <img src={uploadIcon} alt="upload Icon" className={styles.uploadIcon}/>
            <div className={styles.text}>{text}</div>
          </>
      }
    </div>
  );
}

export default UploadAvatar;
