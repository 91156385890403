import React, { useRef } from "react";
import styles from './DeleteModal.module.scss';
import closeIcon from "../../../img/closeIcon.svg";
import { Button } from "../../Button/Button";
import { useAppDispatch } from "../../../store/hooks";
import { useCloseModal } from "../hooks";
import { deleteSelectedWebViews, deleteWebView } from "../../../store/reducers/webViewReducer";


type Props = {
  hideModal: () => void;
  webviewId?: number;
};


export const DeleteWebViewModal: React.FC<Props> = ({ hideModal, webviewId }) => {
  const dispatch = useAppDispatch();

  const modalRef = useRef<HTMLDivElement>(null);

  useCloseModal(modalRef, hideModal);

  return (
    <div className="modal-background">
      <div className={styles.wrapper} ref={modalRef}>
        <div className="modal-titleContainer">
          <div className="modal-title">Удалить WebView</div>
          <img
            src={closeIcon}
            alt="close icon"
            className="modal-closeIcon"
            onClick={hideModal}
          />
        </div>
        <div className={styles.warning}>Вы точно хотите удалить выбранные WebView?</div>
        <div className={styles.info}>Удаленные WebView будет невозможно восстановить</div>
        <div className="modal-bottomWarapper">
          <Button
            onClick={() => {
              if (webviewId) {
                dispatch(deleteWebView(webviewId)).then(hideModal)
              } else {
                dispatch(deleteSelectedWebViews()).then(hideModal)
              }
            }}
          >
            Удалить
          </Button>
          <button type="button" onClick={hideModal} className="modal-closeBtn">Закрыть</button>
        </div>

      </div>
    </div>
  )
}