import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

import { Checkbox } from "../Checkbox/Checkbox";
import { TableTools } from "../TableTools/TableTools";
import styles from "./MainTableTemplate.module.scss";
import { useAppSelector } from "../../store/hooks";
import { getEnumsStatus } from "../../store/reducers/globalDataReducer";
import { ListItemData, Status } from "../../types/api";
import { ViewType } from "../../types/global";

type Props = {
  checkedItemsList: Set<number>,
  itemsList: {
    data: ListItemData[],
    count: number,
  },
  allCheckHandler: (e: React.ChangeEvent<HTMLInputElement>) => void,
  singleCheckHandler: (item: ListItemData) => (e: React.ChangeEvent<HTMLInputElement>) => void,
  viewType : ViewType

}

export const MainTableTemplate: React.FC<Props>  = ({
  checkedItemsList,
  itemsList,
  allCheckHandler,
  singleCheckHandler,
  viewType
}) => {
  const statusesList = useAppSelector(getEnumsStatus);

  const statusToLabel = useMemo(() => {
    const res: { [key: string]: string } = {};
    for (const status of statusesList) {
      res[status.value] = status.label;
    }

    return res;
  }, [statusesList]);



  return (
    <>
      <SnackbarProvider
        maxSnack={10}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      />
      <table className={styles.table}>
        <thead>
        <tr>
          <th>
            <Checkbox
              checked={itemsList.data.length === checkedItemsList.size && itemsList.data.length !== 0}
              onChange={allCheckHandler}
            />
          </th>
          <th className={styles.nameV}>{viewType === "pwa" ? "PWA" : "WebView"}</th>
          <th>Статус</th>
          <th>Домен</th>
          {viewType === "webView" ? <th></th>: <th>Вертикаль</th>}
          <th>Гео</th>
          <th></th>
        </tr>
        </thead>
        <tbody className={styles.tableContent}>
        {itemsList.data.length > 0 ? itemsList.data.map((item, index) => (
          <tr key={index + "trow"}>
            <td>
              <Checkbox
                checked={checkedItemsList.has(item.id)}
                onChange={singleCheckHandler(item)}
              />
            </td>
            <td className={styles.nameV}> {
              item.icon && <img src={item.icon} alt="pwa icon" className={styles.icon}/>
            }<Link to={viewType === "pwa" ? `/edit/${item.id}` : `/edit-web-view/${item.id}`} className={styles.link}>{item.name}</Link></td>
            <td className={item.status === Status.in_process ? styles.status : styles.statusV}>{
              item.status
                ? statusToLabel[item.status]
                : ""
            }</td>
            <td className={item.domain && styles.domain}>
              {item.domain ? <a
                href={`https://${item.domain}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.domain}
              </a> : '—'}
            </td>
            <td className={styles.statusV}>
              {item.vertical}
            </td>
            <td>
              <div className={styles.countriesListContainer}>
                {item.countries && item.countries.length > 0 ?
                  item.countries.length > 4
                    ? <Tooltip title={item.countries && item.countries.join(', ')}>
                      <div className={styles.countriesList}>
                        {item.countries && item.countries.join(', ')}
                      </div>
                    </Tooltip>
                    : <div className={styles.countriesList}>
                      {item.countries && item.countries.join(', ')}
                    </div> : "Все страны"
                }
              </div>
            </td>
            <td>
              <TableTools data={item} viewType={viewType}/>
            </td>
          </tr>
        )) : (
          <tr>
            <td className={styles.emptyState}>Пока нет записей</td>
          </tr>
        )}
        </tbody>
      </table>
    </>
  )
    ;
};
