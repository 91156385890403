
import { WebViewName } from "./WebViewName/WebViewName";
import { WebViewTracker } from "./WebViewTracker/WebViewTracker";
import { WebViewBot } from "./WebViewBot/WebViewBot";
import { WebViewCloak } from "./WebViewCloak/WebViewCloak";
import { WebViewContent } from "./WebViewContent/WebViewContent";


export const TABSWEBVIEW = [
  WebViewName,
  WebViewBot,
  WebViewTracker,
  WebViewCloak,
  WebViewContent
];

export const MAX_PAGE_NUMBER = TABSWEBVIEW.length - 1;