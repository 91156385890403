import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getDefaultParams } from "../../api/helpers/scripts";
import { RootState } from "../store";
import {
  getWebViewList,
  deleteWebView as deleteWebViewAPI,
  getWebViewDomainFilterList,
  getWebViewFilterList, getWebViewPayment, deleteBulkWebView, duplicateWebView
} from "../../api/webView";
import { SelectList } from "../../types/global";
import { ErrorMessage, ListItemData, Status } from "../../types/api";
import { updateWalletBalance } from "./globalDataReducer";
import { AxiosError } from "axios";
import { ActivateStatus} from "./pwaListReducer";


export const DEFAULT_ROWS_PER_PAGE = 10;

type initialState = {
  webViewList : {
    data: ListItemData[],
    count: number
  },
  checkedWebViews: Set<number>,
  filters: {
    list: SelectList,
    domain: SelectList,
  },
}

const initialState: initialState = {
  webViewList: {
    data: [],
    count: 0
  },
  checkedWebViews: new Set(),
  filters: {
    list: [],
    domain: [],
  },
}

function getWebViewListParams() {
  const params = new URLSearchParams(window.location.search);
  return getDefaultParams(params, DEFAULT_ROWS_PER_PAGE);
}


export const getWebViewListData = createAsyncThunk<
  Awaited<ReturnType<typeof getWebViewList>>,
  void
>(
  'webViewListReducer/getWebViewList',
  async (_, { signal } ) => {
    return await getWebViewList(getWebViewListParams(), signal);
  },
);

export const getFiltersWebViewThunk = createAsyncThunk(
  'webViewListReducer/getFiltersWebViewThunk',
  async () => {
    const [
      domainWebViewFilterList,
      webViewFilterList,
    ] = await Promise.all([
      getWebViewDomainFilterList(),
      getWebViewFilterList(),

    ]);

    return {
      domainWebViewFilterList,
      webViewFilterList,
    };
  },
);

export const payForWebView = createAsyncThunk<
  boolean,
  number,
  { state: RootState, rejectValue: ErrorMessage }
>(
  'webViewListReducer/payForWebView',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      dispatch(changeWebViewStatusAction({
        webview_id: id,
        status: Status.in_process
      }));
      await getWebViewPayment(id);
      await dispatch(updateWalletBalance());

      return true;
    } catch (err: any) {
      const error = err as AxiosError<ErrorMessage>;
      await dispatch(getWebViewListData());
      throw rejectWithValue(error.response!.data);
    }
  },
);

export const payForSelectedWebViews = createAsyncThunk<
  boolean | ActivateStatus,
  void,
  { state: RootState, rejectValue: ErrorMessage }
>(
  'webViewListReducer/payForSelectedWebViews',
  async (
    _,
    { dispatch, getState, rejectWithValue }
  ) => {
    const state = getState();
    const selectedWebViews = [...state.webViewListReducer.checkedWebViews];
    const getWebViewList = state.webViewListReducer.webViewList.data;
    const webViewToPay: number[] = [];
    for (const id of selectedWebViews) {
      const webviewData = getWebViewList.find(pwa => pwa.id === id)!;
      if (webviewData.status === "not_paid") {
        webViewToPay.push(id);
        dispatch(changeWebViewStatusAction({
          webview_id: id,
          status: Status.in_process
        }));
      }
    }

    if (webViewToPay.length === 0) return ActivateStatus.AlreadyPaid;

    try {
      await Promise.all(webViewToPay.map(webview => getWebViewPayment(webview)));
      await dispatch(getWebViewListData());
      await dispatch(updateWalletBalance());

      return true;
    } catch (err: any) {
      await dispatch(getWebViewListData());
      return ActivateStatus.Error;
    }
  },
);


export const deleteWebView = createAsyncThunk<void, number, { state: RootState }>(
  'webViewListReducer/deleteWebView',
  async (id, { dispatch }) => {
    await deleteWebViewAPI(id);
    await dispatch(getWebViewListData());
    await dispatch(getFiltersWebViewThunk());
  },
);

export const deleteSelectedWebViews = createAsyncThunk<
  void, void, { state: RootState }
>(
  'webViewListReducer/deleteSelectedWebViews',
  async (_, { dispatch, getState }) => {
    const state = getState();
    const checkedWebViewList = [...state.webViewListReducer.checkedWebViews];
    await deleteBulkWebView(checkedWebViewList);
    await dispatch(getWebViewListData());
    await dispatch(getFiltersWebViewThunk());
  },
);

export const duplicateWebViewThunk = createAsyncThunk<void, number, { state: RootState }>(
  'webViewListReducer/duplicateWebView',
  async (id, { dispatch }) => {
    await duplicateWebView(id);
    await dispatch(getWebViewListData());
    await dispatch(getFiltersWebViewThunk());
  },
);


const webViewListReducer = createSlice({
  name: "webViewListReducer",
  initialState,
  reducers: {
    selectWebView(state, action: PayloadAction<number>) {
      state.checkedWebViews.add(action.payload);
    },
    deselectWebView(state, action: PayloadAction<number>) {
      state.checkedWebViews.delete(action.payload);
    },
    selectAllWebViews(state) {
      state.checkedWebViews = new Set(state.webViewList.data.map(item => item.id));
    },
    selectNoWebView(state) {
      state.checkedWebViews = new Set();
    },

    changeWebViewStatusAction(state, action: PayloadAction<{ webview_id: number, status: string }>) {
      const index = state.webViewList.data.findIndex(webview => webview.id === action.payload.webview_id);
      if (index !== -1) {
        state.webViewList.data[index].status = action.payload.status;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWebViewListData.fulfilled, (state, action) => {
      state.webViewList.data = action.payload.results;
      state.webViewList.count = action.payload.count;
    });
    builder.addCase(getFiltersWebViewThunk.fulfilled, (state, action) => {
      state.filters.domain = action.payload.domainWebViewFilterList.result
        .map(({ address }) => ({ label: address, value: address }));
      state.filters.list = action.payload.webViewFilterList.result
        .map(({ name }) => ({ label: name, value: name }));
    });
  }
});

export const {
  selectWebView,
  deselectWebView,
  selectAllWebViews,
  selectNoWebView,
  changeWebViewStatusAction,
} = webViewListReducer.actions;

export const getCheckedWebViewList = (state: RootState) => state.webViewListReducer.checkedWebViews;

export const getCreatedWebViewList = (state: RootState) => state.webViewListReducer.webViewList;
export const getWebViewFilters = (state: RootState)=> state.webViewListReducer.filters;

export default webViewListReducer.reducer;