import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../store";

type InitialState = {
  name: string,
  bot_token: string,
  offer_url: string,
  button: string,
  message: string,
  cloak : {
    white_url: string,
    status: string,
    type: string,
    countries : number[],
    white_ips: string[],
  }
  navigation: {
    currentPage: number,
    maxUnlockedPage: number,
  },
}

const initialState: InitialState = {
  name: "",
  bot_token: "",
  offer_url: "",
  button: "",
  message: "",
  cloak : {
    white_url: "",
    status: "",
    type: "",
    countries : [],
    white_ips: [],
  },
  navigation: {
    currentPage: 0,
    maxUnlockedPage: 0,
  },
}

const webViewFormReducer = createSlice({
  name: "webViewFormReducer",
  initialState,
  reducers: {
    goToNextPage(state) {
      state.navigation.currentPage += 1;
      if (state.navigation.maxUnlockedPage < state.navigation.currentPage) {
        state.navigation.maxUnlockedPage = state.navigation.currentPage;
      }
    },
    goToPrevPage(state) {
      if (state.navigation.currentPage !== 0) {
        state.navigation.currentPage -= 1;
      }
    },
    setPage(state, action: PayloadAction<number>) {
      state.navigation.currentPage = action.payload;
    },
  },
});

export const getWebViewNavigation = (state: RootState) => state.webViewFormReducer.navigation;

export const {
  goToNextPage,
  goToPrevPage,
  setPage,
} = webViewFormReducer.actions;

export default webViewFormReducer.reducer;