import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducer';
import { enableMapSet } from "immer";

enableMapSet();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: [
          'pwaListReducer.checkedPWAs',
          'webViewListReducer.checkedWebViews'
        ],
      }
    })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
