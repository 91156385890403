import { useFormikContext } from "formik";
import styles from "./PWAName.module.scss";
import { PwaFormValues } from "../helpers/formikHelperPWA";
import FormInput from "../../../components/FormInput";
import { PagesNavigation } from "../PagesNavigation/PagesNavigation";

export const PWAName = () => {
  const { values, errors } = useFormikContext<PwaFormValues>();

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <span className={styles.inputItem}>
          <label>Название PWA</label>
          <FormInput
            name="name"
            placeholder="Введите имя"
          />
        </span>
        {values?.status !== "work" &&
            <span className={styles.inputItem}>
              <label>Sub домен (необязательно)</label>
              <FormInput
                  name="subdomain.address"
                  placeholder="Введите субдомен"
              />
              <div className={styles.subdomainInfoBlock}>
                <div className={styles.infoTitle}>Стоимость Активации PWA $6</div>
              </div>
            </span>
        }
        <PagesNavigation nexButtonDisabled={
          !values.name || !!errors.name}/>
      </div>
    </div>
  );
};
