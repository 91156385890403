import styles from "./PreviewWebView.module.scss";
import cameraIcon from "../../../img/phone/dot.svg";
import React from "react";
import wifiIcon from "../../../img/phone/wifi.svg";
import connectionIcon from "../../../img/phone/connection.svg";
import batteryIcon from "../../../img/phone/battery.svg";
import arrowLeft from "../../../img/botPreView/arrowLeft.svg";
import moreDots from "../../../img/botPreView/moreDots.svg";
import openMenu from "../../../img/botPreView/botMenu.svg";
import messageIcon from "../../../img/botPreView/messageIcon.svg";
import pinIcon from "../../../img/botPreView/pin.svg";
import microphoneIcon from "../../../img/botPreView/microphone.svg";
import { useFormikContext } from "formik";
import { WebViewFormValues } from "../WebViewForm/helpers/formikHelpersWebView";


export const PreviewWebView = () => {

  let now = new Date();
  let hours = now.getHours().toString().padStart(2, '0');
  let minutes = now.getMinutes().toString().padStart(2, '0');
  let current_time = `${hours}:${minutes}`;

  const { values } = useFormikContext<WebViewFormValues>();

  return (
    <div className={styles.wrapper}>
      <div className={styles.outerBorder}>
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            <img
              className={styles.camera}
              src={cameraIcon}
              width={18}
              height={18}
              alt="camera icon"
            />
            <p className={styles.text}>
              {current_time}
            </p>
          </div>
          <div className={styles.headerRight}>
            <img src={wifiIcon} alt="wifi icon"/>
            <img src={connectionIcon} alt="connection icon"/>
            <img src={batteryIcon} alt="batterry icon"/>
          </div>
        </div>
        <div>
          <div className={styles.botTopMenu}>
            <div className={styles.botTopMenuLeft}>
              <img src={arrowLeft} alt="arrowLeft icon"/>
              <div className={styles.botTopMenuContainer}>
                <div className={styles.botTopMenuAvatar}></div>
                <div className={styles.botTopMenuNameContainer}>
                  <div className={styles.botTopMenuName}>{values.bot_name}</div>
                  <div className={styles.botTopMenuBot}>bot</div>

                </div>
              </div>

            </div>
            <div className={styles.botTopMenuRight}>
              <img src={moreDots} alt="more dots"/>
            </div>

          </div>
          <div className={styles.botBody}>
            <div className={styles.botBodyMessageWrapper}>
              <div className={styles.botBodyImageContainer} >
                <img src={values.message_image} alt="bot" />
              </div>
              <div className={styles.botBodyMessageContainer}>
                <div className={styles.botBodyMessage}>{values.message}</div>
                <div className={styles.botBodyMessageTime}>18:28</div>
              </div>
            </div>
            <div className={styles.botBodyBtn}>{values.message_button}</div>

            <div className={styles.botBottomMenu}>
              <div className={styles.botBottomMenuOpen}>
                <img src={openMenu} alt="open menu"/>
                <div>{values.button}</div>
              </div>
              <div className={styles.botBottomMenuMessage}>
                <img src={messageIcon} alt="message icon"/>
                <span>Message</span>
              </div>
              <div>
                <img src={pinIcon} alt="pin icon"/>
                <img src={microphoneIcon} alt="microphone icon"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}