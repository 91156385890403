import React, { useRef } from "react";
import styles from './DeleteModal.module.scss';
import closeIcon from "../../../img/closeIcon.svg";
import { Button } from "../../Button/Button";
import { useAppDispatch } from "../../../store/hooks";
import { deletePWA, deleteSelectedPWAs } from "../../../store/reducers/pwaListReducer";
import { useCloseModal } from "../hooks";


type Props = {
  hideModal: () => void;
  pwaId?: number;
};


export const DeletePWAModal: React.FC<Props> = ({ hideModal, pwaId }) => {
  const dispatch = useAppDispatch();

  const modalRef = useRef<HTMLDivElement>(null);

  useCloseModal(modalRef, hideModal);

  return (
    <div className="modal-background">
      <div className={styles.wrapper} ref={modalRef}>
        <div className="modal-titleContainer">
          <div className="modal-title">Удалить PWA</div>
          <img
            src={closeIcon}
            alt="close icon"
            className="modal-closeIcon"
            onClick={hideModal}
          />
        </div>
        <div className={styles.warning}>Вы точно хотите удалить выбранные PWA?</div>
        <div className={styles.info}>Удаленные PWA будет невозможно восстановить</div>
        <div className="modal-bottomWarapper">
          <Button
            onClick={() => {
              if (pwaId) {
                dispatch(deletePWA(pwaId)).then(hideModal)
              } else {
                dispatch(deleteSelectedPWAs()).then(hideModal)
              }
            }}
          >
            Удалить
          </Button>
          <button type="button" onClick={hideModal} className="modal-closeBtn">Закрыть</button>
        </div>

      </div>
    </div>
  )
}