import React, { useEffect } from "react";

import { MainTableTemplate } from "./MainTableTemplate";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { decodePaymentEvent, getPaymentWebSocket, wsOptions } from "../../api/webSocket";
import { enqueueSnackbar } from "notistack";
import useWebSocket from "react-use-websocket";
import { ListItemData } from "../../types/api";
import { changePwaDomainAndStatusAction } from "../../store/reducers/pwaListReducer";
import {
  deselectWebView,
  getCheckedWebViewList,
  getCreatedWebViewList,
  selectAllWebViews,
  selectNoWebView, selectWebView
} from "../../store/reducers/webViewReducer";
import { ViewType } from "../../types/global";

export const WebViewTable = () => {
  const dispatch = useAppDispatch();
  const checkedWebViewItems = useAppSelector(getCheckedWebViewList);
  const webViewList = useAppSelector(getCreatedWebViewList);

  const { lastMessage } = useWebSocket(getPaymentWebSocket, wsOptions);

  useEffect(() => {
    if (!lastMessage) return;

    const paymentData = decodePaymentEvent(lastMessage);

    dispatch(changePwaDomainAndStatusAction({
      pwa_id: paymentData.data.id,
      status: paymentData.data.status,
      domain: paymentData.data.domain
    }));

    if (paymentData.status === "success") {
      enqueueSnackbar(`WebView "${paymentData.data.name}" успешно оплачен`,
        { variant: 'success', style: { backgroundColor: '#01875F' } });
    } else {
      enqueueSnackbar(`При оплате WebView "${paymentData.data.name}" произошла ошибка: ${paymentData.error_message}`,
        { variant: 'error', style: { backgroundColor: '#D11A2B' } });
    }
  }, [lastMessage]);

  return <MainTableTemplate
    viewType={ViewType.webView}
    checkedItemsList={checkedWebViewItems}
    itemsList={webViewList}
    allCheckHandler={(e) => {
      if (e.target.checked) {
        dispatch(selectAllWebViews());
      } else {
        dispatch(selectNoWebView());
      }
    }}
    singleCheckHandler={(item: ListItemData) => (e) => {
      if (e.target.checked) {
        dispatch(selectWebView(item.id));
      } else {
        dispatch(deselectWebView(item.id));
      }
    }}
  />;

}