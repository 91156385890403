import { useFormikContext } from "formik";
import { WebViewFormValues } from "../helpers/formikHelpersWebView";
import styles from "./WebViewContent.module.scss";
import FormInput from "../../../../components/FormInput";
import uploadIcon from "../../../../img/uploadIcon.svg";
import React from "react";
import { UploadImageWebViewModal } from "../UploadImageWebViewModal/UploadImageWebViewModal";
import { FormTextArea } from "../../../../components/TextArea/FormTextArea";
import Modal from "@mui/material/Modal/Modal";
import { WebViewPagesNavigation } from "../WebViewPagesNavigation/WebViewPagesNavigation";
import { PreviewWebView } from "../../PreviewWebView/PreviewWebView";


export const WebViewContent = ()=> {
  const { values, errors, setFieldValue } = useFormikContext<WebViewFormValues>();

  const [openUploadImageModal, setOpenUploadImageModal] = React.useState(false);
  const handleModalClose = () => {
    setOpenUploadImageModal(false);
  };

  const handleSaveImg = (img: string)=> {
    console.log({img})
    setFieldValue("message_image", img);
    setOpenUploadImageModal(false);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div>
        <div className={styles.inputItem}>
        <label>Выберите картинку которая будет отображаться в сообщении</label>
        <div className={styles.downloadFilesContainer}>
          <button
            type="button"
            onClick={() => setOpenUploadImageModal(true)}

            className={styles.uploadBtn}
          >
            <p> Загрузить картинку</p>
            <img width={24} height={24} src={uploadIcon} alt="upload Icon"/>
          </button>
        </div>
        </div>
        <span className={styles.inputItem}>
          <label>Кнопка</label>
          <FormInput
            name="button"
            placeholder="Введите название кнопки"
          />
        </span>

        <span className={styles.inputItem}>
          <label>Сообщение кнопки</label>
          <FormInput
            name="message_button"
            placeholder="Введите сообщение кнопки"
          />
        </span>

        <div className={styles.inputItem}>
          <label>Сообщение</label>
          <FormTextArea
            name="message"
            bg="white"
          />
        </div>
        </div>
          <PreviewWebView/>

      </div>
      <Modal open={openUploadImageModal} onClose={handleModalClose}>
        <UploadImageWebViewModal handleClose={handleModalClose} handleSave={handleSaveImg}/>
      </Modal>
      <WebViewPagesNavigation
        nexButtonDisabled={!values.message || !!errors.message || !values.message_button || !!errors.message_button}
      />

    </div>
  )
}