/******************** ENUMS ********************/

export enum Status {
  not_paid = 'not_paid',
  in_process = 'in_process',
  work = 'work',
  stop = 'stop',
  error = 'error',
}

export enum StartStopState {
  start = 'start',
  stop = 'stop',
}

/******************** TYPES ********************/
type ServerEnumStruct = {
  choices: {
    value: string,
    display_name: string,
  }[],
};

export type CreatePwaOptions = {
  actions: {
    POST: {
      status: ServerEnumStruct,
      application: { children: { category: ServerEnumStruct } },
      vertical: {
        children: {
          preview_template: { children: { template_type: ServerEnumStruct } },
          template: ServerEnumStruct,
          vertical: ServerEnumStruct,
        }
      },
    }
  }
}

export type UserWhiteIpsList = {
  ip: string,
}[]

export type CloaksOptions = {
  actions: {
    POST: {
      status: ServerEnumStruct,
      type: ServerEnumStruct,
      whitepage_type: ServerEnumStruct,
      wordpress_theme: ServerEnumStruct,
    }
  }
}

export type Tokens = AccessToken & {
  refresh: string,
}

export type AccessToken = {
  access: string,
}

export type UserData = {
  id: number,
  username: string,
  is_staff: boolean,
  is_partner_user: boolean,
}

export type VerticalTemplate = {
  id: number,
  icon: string,
  screenshot_1: string,
  screenshot_2: string,
  screenshot_3: string,
  screenshot_4: string,
  template_type: string,
  owner: number,
}


export type Country = {
  id: number,
  name: string,
  code: string,
}

export type Comment = {
  avatar?: string,
  author: string,
  rating: number,
  likes: number,
  date: string,
  text: string,
}

export type Sectors = {
  id: number,
  sector_number: number,
  sector_value: string,
  is_win: boolean[],
  prelanding: number,
}

export type PreLanding = {
  id: number,
  sectors: Sectors[],
  logo?: string,
  name: string,
  type: string,
  title: string,
  button_text: string,
  respin_button_text: string,
  spin_count: number,
  modal_window_title: string,
  modal_window_text: string,
  modal_window_button_text: string,
  design: number,
  owner?: string,
}

export type Tracker = {
  id?: number,
  offer_url: string,
  traffic_back?: string,
};

export type Cloak = {
  white_url?: string,
  status?: string,
  type: string,
  countries: number[],
  white_ips?: string[]
  whitepage_type?: string,
  wordpress_theme?: string,
}

export type Application = {
  name?: string,
  add_to_wishlist?: string,
  editors_choice?: string,
  reviews_text? :string,
  available_devices? : string;
  language?: string,
  category?: string,
  category_text?: string,
  author?: string,
  advertising?: string,
  rating_text?: string,
  size?: number,
  age_text?: string,
  age?: number,
  install?: string,
  title?: string,
  installation?: string,
  download?: string,
  downloading?: string,
  open?: string,
  about?: string,
  read_more?: string,
  hide?: string,
  description?: string,
  whats_new_text?: string,
  whats_new?: string,
  all_reviews_text?: string,
  hide_reviews_text?: string,
  reviews?: string,
  rating?: number,
  reviews_count?: number,
  total?: string,
  five_star?: number,
  four_star?: number,
  three_star?: number,
  two_star?: number,
  one_star?: number,
  additional_information?: string,
  developer?: string,
  updated?: string,
  update_date?: string,
  size_text?: string,
  installs?: string,
  installs_count?: string,
  current_version_text?: string,
  current_version?: number,
  cookies_request?: string,
  cookies_accept?: string,
  cookies_reject?: string,
  data_safety? : string,
  data_share?: string,
  data_collect?: string,
  data_see_details? :string,
  security_about? : string,
  security_info?: string,
  developer_contact?: string,
  review_helpful?: string,
  find_this_helpful?: string,
  helpful_yes?: string,
  helpful_no?: string,
}

export type PwaCreate = {
  name: string,
  subdomain?: {
    address: string
  },
  tracker: Tracker,
  cloak: Omit<Cloak, 'countries'> & { countries?: number[] },
  application: Partial<Application>,
  vertical?: {
    template: string,
    preview_template: number,
    vertical?: string,
  },
  comments?: (Comment & { reply: Comment })[],
  preland?: number,
}

export type PwaDataGet = Omit<PwaCreate, 'cloak'> & {
  status: Status,
  tracker: Tracker & { pwa: number },
  domain?: string,
  cloak?: Cloak,
  application: Partial<Application> & { pwa: number },
  vertical?: {
    id?: number,
    preview_template: VerticalTemplate,
    template: string,
    vertical: string,
  },
  comments: (Comment & { reply: Comment[] })[],
  owner?: number,
}

export type ListItemData = {
  id: number,
  name: string,
  icon?: string,
  status?: string,
  domain?: string,
  vertical?: string,
  countries: string[],
}

export type PwaStatus = {
  pwa_id: number,
  state: StartStopState
}


export type PaymentResponse = {
  transaction_id: number,
  status: string
}

export type WebViewPaymentRes= {
  id: number,
  status: string
}

export type ErrorMessage = {
  status?: string,
  error_status: string,
  error_message?: string
}

export type AIComments = {
  success: boolean,
    result: [
      {
        author: string,
        comment: string,
      }
    ]
  }

export type AICommentsBody = {
    amount: number,
    keywords: string,
    localization: string,
    is_positive: boolean,

}

export type PwaCreatedError = {
  success: string,
  message: string
}