import React, { useEffect } from "react";

import { MainTableTemplate } from "./MainTableTemplate";
import {
  changePwaDomainAndStatusAction,
  deselectPWA,
  getCheckedPWAList, getCreatedPWAList,
  selectAllPWAs,
  selectNoPWA,
  selectPWA,
} from "../../store/reducers/pwaListReducer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { decodePaymentEvent, getPaymentWebSocket, wsOptions } from "../../api/webSocket";
import { enqueueSnackbar } from "notistack";
import useWebSocket from "react-use-websocket";
import { ListItemData } from "../../types/api";
import { ViewType } from "../../types/global"

export const PwaTable = () => {
  const dispatch = useAppDispatch();
  const checkedPwaItems = useAppSelector(getCheckedPWAList);
  const pwaList = useAppSelector(getCreatedPWAList);

  const { lastMessage } = useWebSocket(getPaymentWebSocket, wsOptions);

  useEffect(() => {
    if (!lastMessage) return;

    const paymentData = decodePaymentEvent(lastMessage);

    dispatch(changePwaDomainAndStatusAction({
      pwa_id: paymentData.data.id,
      status: paymentData.data.status,
      domain: paymentData.data.domain
    }));

    if (paymentData.status === "success") {
      enqueueSnackbar(`PWA "${paymentData.data.name}" успешно оплачен`,
        { variant: 'success', style: { backgroundColor: '#01875F' } });
    } else {
      enqueueSnackbar(`При оплате PWA "${paymentData.data.name}" произошла ошибка: ${paymentData.error_message}`,
        { variant: 'error', style: { backgroundColor: '#D11A2B' } });
    }
  }, [lastMessage]);

  return <MainTableTemplate
    viewType={ViewType.pwa}
    checkedItemsList={checkedPwaItems}
    itemsList={pwaList}
    allCheckHandler={(e) => {
      if (e.target.checked) {
        dispatch(selectAllPWAs());
      } else {
        dispatch(selectNoPWA());
      }
    }}
    singleCheckHandler={(item: ListItemData) => (e) => {
      if (e.target.checked) {
        dispatch(selectPWA(item.id));
      } else {
        dispatch(deselectPWA(item.id));
      }
    }}
  />;
}