import React, { useEffect } from "react";

import styles from "./Table.module.scss";
import trueIcon from "../../img/trueIcon.svg";
import falseIcon from "../../img/falseIcon.svg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getClicksStatisticsData,
  getClicksStatisticsThunk,
} from "../../store/reducers/statisticReducer";
import { formatTimestamp } from "../../helpers/formatData";
import { ParamsSortIcon } from "../SortIcon/ParamsSortIcon";
import { useSearchParams } from "react-router-dom";
import { ERROR, ordering, params } from "../../api/helpers/constants";
import { ParamsPagination } from "../Pagination/ParamsPagination";
import { getDefaultParams } from "../../api/helpers/scripts";
import { Loader } from "../Loader/Loader";


const DEFAULT_ROWS_PER_PAGE = 10

export const ClicksStatisticTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const statisticsData = useAppSelector(getClicksStatisticsData);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const promise = dispatch(getClicksStatisticsThunk(getDefaultParams(
      searchParams, DEFAULT_ROWS_PER_PAGE
    )));
    promise.unwrap().catch(error => {
      if (error.detail === ERROR.INVALID_PAGE_ERROR) {
        setSearchParams(_params => {
          _params.set(params.page, '1');
          return _params;
        });
      }
    });


    return () => {
      promise.abort();
    }
  }, [dispatch, searchParams]);


  return (
    <>
      <table className={`${styles.table} ${styles.clicksStatisticTable}`}>
        <thead>
        <tr>
          {([
            { ordering: ordering.pwa, title: "PWA" },
            { ordering: ordering.click_id, title: "Клик ID" },
            { ordering: ordering.domain, title: "Домен" },
            { ordering: ordering.country, title: "Страна" },
            { ordering: ordering.is_install, title: "Установки" },
            { ordering: ordering.is_open, title: "Открытия" },
            { ordering: ordering.is_lead, title: "Лиды" },
            { ordering: ordering.is_sale, title: "Продажи" },
            { ordering: ordering.updated_at, title: "Дата" },
          ] as { ordering: string, title: string }[]).map(column => (
            <th key={column.ordering}>{column.title}
              <ParamsSortIcon
                sortsBy={column.ordering}
              />
            </th>
          ))}
        </tr>
        </thead>
        <tbody className={styles.tableContent}>
        {statisticsData.data.length > 0 ? statisticsData.data.map((row, index) => (
          <tr key={index + "trow"}>
            <td>{row.pwa}</td>
            <td>{row.click_id}</td>
            <td>{row.domain}</td>
            <td>{row.country}</td>
            <td>{row.is_install ? <img src={trueIcon} alt="true icon"/> : <img src={falseIcon} alt="false icon"/>}</td>
            <td>{row.is_open ? <img src={trueIcon} alt="true icon"/> : <img src={falseIcon} alt="false icon"/>}</td>
            <td>{row.is_lead ? <img src={trueIcon} alt="true icon"/> : <img src={falseIcon} alt="false icon"/>}</td>
            <td>{row.is_sale ? <img src={trueIcon} alt="true icon"/> : <img src={falseIcon} alt="false icon"/>}</td>
            <td>{formatTimestamp(row.updated_at)}</td>
          </tr>
        )) : (
          <tr>
            <td className={styles.emptyState}><Loader/></td>
          </tr>
        )}
        </tbody>
      </table>
      <ParamsPagination
        listLength={statisticsData.count}
        defaultRowsPerPage={DEFAULT_ROWS_PER_PAGE}
      />
    </>
  );
};
