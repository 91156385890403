import { authorizedRequests } from "./auth";
import { BASE_URL } from "./constants";

import { PaginatedResults, WrappedResults } from "./helpers/types";
import { ListItemData, WebViewPaymentRes } from "../types/api";
import { WebViewFormValues } from "../pages/WebView/WebViewForm/helpers/formikHelpersWebView";

export async function getWebViewList(params: URLSearchParams, signal: AbortSignal) {
  const response = await authorizedRequests.get(
    BASE_URL + `/webview/?` +params,
    { signal }
  );
  return response.data as PaginatedResults<ListItemData[]>;
}

export async function getWebViewData(id: number) {
  const response = await authorizedRequests.get(
    BASE_URL + `/webview/${id}/`
  );

  return response.data as WebViewFormValues;
}

export async function deleteWebView(id: number) {
  await authorizedRequests.delete(BASE_URL + `/webview/${id}/`);
}

export async function deleteBulkWebView(ids: number[]) {
  return await authorizedRequests.delete(
    BASE_URL + "/webview/delete_bulk/",
    { data: { ids } }
  );
}

export async function getWebViewPayment(id:number) {
  const response = await authorizedRequests.get(
    BASE_URL + `/webview/${id}/payment/`
  );

  return response.data as WebViewPaymentRes;
}

export async function duplicateWebView(id:number) {
  await authorizedRequests.get(
    BASE_URL + `/webview/${id}/duplicate/`
  );
}

export async function getWebViewFilterList() {
  const response = await authorizedRequests.get(
    BASE_URL + '/webview/filter_list/'
  );
  return response.data as WrappedResults<{ id: number, name: string }[]>;
}

export async function getWebViewDomainFilterList() {
  const response = await authorizedRequests.get(
    BASE_URL + '/webview/domains_filter_list/'
  );

  return response.data as WrappedResults<{ id: number, address: string }[]>;
}

export async function updateWebView(id: number, webViewData: WebViewFormValues) {
  await authorizedRequests.put(
    BASE_URL + `/webview/${id}/`,
    webViewData
  );
}

export async function sendWebView(webViewData: WebViewFormValues) {
  const response = await authorizedRequests.post(
    BASE_URL + '/webview/',
    webViewData
  );
  return response.data as WebViewFormValues
}
