import React from "react";
import { useFormikContext } from "formik";

import indexStyles from "./styles/index.module.scss";
import headerStyles from "./styles/header.module.scss";
import aboutStyles from "./styles/about.module.scss";
import dataStyles from "./styles/data.module.scss";
import ratingStyles from "./styles/rating.module.scss";
import commentsStyles from "./styles/comments.module.scss";
import whatsNewStyles from "./styles/whatsNew.module.scss";

// images
import checkMark from "../../../img/templates/checkMark.svg";
import icon18 from "../../../img/templates/18.png";
import reviewStar from "../../../img/templates/reviewStar.svg";
import commentStar from "../../../img/templates/commentStar.svg";
import commentStarEmpty from "../../../img/templates/commentStarEmpty.svg";
import commentAvatarPlaceholder from "../../../img/commentAvatarPlaceholder.svg";
import iconPlaceholder from "../../../img/phone/iconPlaceholder.svg";

import { PwaFormValues } from "../../../pages/PwaForm/helpers/formikHelperPWA";
import { useAppSelector } from "../../../store/hooks";
import { getSelectedPreviewTemplate } from "../../../store/reducers/pwaFormReducer";
import { transformDate } from "../../../helpers/formatData";


export const NewPlayMarketContent: React.FC = () => {
  const { values } = useFormikContext<PwaFormValues>();
  const selectedPreviewTemplate = useAppSelector(getSelectedPreviewTemplate);

  const str = values.application.description;
  const formattedTextArea = str?.split('\n');

  return (
    <div className={indexStyles.app}>
      <div className={headerStyles.header}>
        <div className={headerStyles.title}>
          <img
            className={headerStyles.icon}
            src={selectedPreviewTemplate?.icon || iconPlaceholder}
            alt="icon"
          />
          <div>
            <div className={headerStyles.title_name}>{values.application.name}</div>
            <div className={headerStyles.title_author}>{values.application.author}</div>
            <div className={headerStyles.title_ads}>{values.application.category}</div>
          </div>
        </div>
        <div className={headerStyles.info}>
          <div className={headerStyles.info_item}>
            <div className={headerStyles.topLine}>{values.application.rating}
              <span className={`material-icons ${indexStyles.md12}`}>star</span>
            </div>
            <div
              className={headerStyles.bottomLine}>{values.application.reviews_count}K {values.application.reviews_text}</div>
          </div>
          <div className={headerStyles.info_item}>
            <div className={headerStyles.topLine}>{values.application.installs_count}</div>
            <div className={headerStyles.bottomLine}>{values.application.installs}</div>
          </div>
          <div className={headerStyles.info_item}>
            <div className={headerStyles.topLine}>
              <img src={checkMark} alt="checkMark"/>
            </div>
            <div className={headerStyles.bottomLine}>{values.application.editors_choice}</div>
          </div>
          <div className={headerStyles.info_item}>
            <div className={headerStyles.topLine}><img src={icon18} alt="18+" width="16" height="16"/></div>
            <div className={headerStyles.bottomLine}>
              <div>{values.application.age_text} {values.application.age}</div>
              <div className={`material-icons ${indexStyles.md12}`} aria-hidden="true">info</div>
            </div>
          </div>
        </div>
        <button type="button" className={headerStyles.installBtn} id="installBtn">
          {values.application.download}
        </button>
        <div className={headerStyles.wishlist}>
          <div>
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path
                fill="#01875f"
                d="M7 3H17C18.1045 3 19 3.8955 19 5V21L12 18L5 21L5.01075 5C5.01075 3.8955 5.8965 3 7 3ZM12 15.824L17 18V5H7V18L12 15.824ZM13 7V9H15V11H13V13H11V11H9V9H11V7H13Z"></path>
            </svg>
          </div>
          <div>{values.application.add_to_wishlist}</div>
        </div>
        <div className={headerStyles.extraInfo}>
          <div className={`material-icons ${indexStyles.md20}`}>devices</div>
          <div>{values.application.available_devices}</div>
        </div>
      </div>

      <div className={aboutStyles.about}>
        <div className={aboutStyles.imagesContainer}>
          <img src={selectedPreviewTemplate?.screenshot_1}
               alt="template" className={aboutStyles.img}/>
          <img src={selectedPreviewTemplate?.screenshot_2}
               alt="template" className={aboutStyles.img}/>
          <img src={selectedPreviewTemplate?.screenshot_3}
               alt="template" className={aboutStyles.img}/>
          <img src={selectedPreviewTemplate?.screenshot_4}
               alt="template" className={aboutStyles.img}/>
        </div>

        <div className={aboutStyles.about_titleContainer}>
          <h2>{values.application.about}</h2>
          <div className={`material-icons ${indexStyles.md24} ${indexStyles.arrow_right}`}
               id="modalOpenButton">arrow_forward
          </div>
        </div>

        <div className={aboutStyles.about_description}>
          {formattedTextArea && formattedTextArea.map(paragraph => <p
            key={paragraph + 20 * Math.random()}>{paragraph}</p>)}
        </div>

        <div className={aboutStyles.updated}>
          <div className={aboutStyles.updated_title}>{values.application.updated}</div>
          <div
            className={aboutStyles.updated_date}>{transformDate(values.application.update_date || "15-05-2024")}</div>
        </div>
        <div className={aboutStyles.category}>Casino</div>
      </div>


      <div className={dataStyles.data}>
        <div className={dataStyles.data_top}>
          <h2>{values.application.data_safety}</h2>
          <div className={`material-icons ${indexStyles.md24} ${indexStyles.arrow_right}`}>arrow_forward</div>
        </div>

        <p>{values.application.security_about}</p>
        <div className={dataStyles.data_frame}>
          <div className={dataStyles.data_block}>
            <img
              src="https://play-lh.googleusercontent.com/iFstqoxDElUVv4T3KxkxP3OTcuFvWF5ZQQjT7aIxy4n2uaVigCCykxeG6EZV9FQ10X1itPj1oORm=s20-rw"
              srcSet="https://play-lh.googleusercontent.com/iFstqoxDElUVv4T3KxkxP3OTcuFvWF5ZQQjT7aIxy4n2uaVigCCykxeG6EZV9FQ10X1itPj1oORm=s40-rw 2x"
              className={dataStyles.data_img} aria-hidden="true" alt="Изображение значка"
              data-iml="3660.3999999910593"/>
            <div>{values.application.data_share}</div>
          </div>

          <div className={dataStyles.data_block}>
            <div className={dataStyles.data_block}>
              <img
                src="https://play-lh.googleusercontent.com/12USW7aflgz466ifDehKTnMoAep_VHxDmKJ6jEBoDZWCSefOC-ThRX14Mqe0r8KF9XCzrpMqJts=s20-rw"
                srcSet="https://play-lh.googleusercontent.com/12USW7aflgz466ifDehKTnMoAep_VHxDmKJ6jEBoDZWCSefOC-ThRX14Mqe0r8KF9XCzrpMqJts=s40-rw 2x"
                className={dataStyles.data_img} aria-hidden="true" alt="Изображение значка"
                data-iml="3660.3999999910593"/>
              <div>{values.application.data_collect}</div>
            </div>

          </div>

          <div className={dataStyles.data_moreDetails}>{values.application.data_see_details}</div>

        </div>
      </div>

      <div className={ratingStyles.rating}>
        <div className={ratingStyles.rating_top}>
          <div className={ratingStyles.rating_title}>{values.application.reviews}</div>
          <div className={`material-icons ${indexStyles.md24} ${indexStyles.arrow_right}`}>arrow_forward</div>
        </div>
        <div className={ratingStyles.reviewsContainer}>
          <div className={ratingStyles.leftContainer}>
            <div className={ratingStyles.reviews_rating}>{values.application?.rating}</div>
            <div className={ratingStyles.reviews_rating_stars}>
              <img src={reviewStar} alt="review star"/>
              <img src={reviewStar} alt="review star"/>
              <img src={reviewStar} alt="review star"/>
              <img src={reviewStar} alt="review star"/>
              <img src={reviewStar} alt="review star"/>

              {/*<!-- `width` should be calculated as width = (5 - rating) / 5 * 100% = 4% -->*/}
              {/*<!-- example width = (5 - 4.8) / 5 * 100% -->>*/}
              <div className={ratingStyles.reviews_rating_stars_mask} style={{
                width: ((5 - (values.application?.rating || 0)) / 5 * 100) + '%'
              }}></div>
            </div>
            <div
              className={ratingStyles.reviews_number}>{values.application.reviews_count}K {values.application.reviews_text}</div>
          </div>
          {/*<!-- here width = number from pwa data-->>*/}
          <div className={ratingStyles.rightContainer}>
            <div className={ratingStyles.barContainer}>
              <span className={ratingStyles.number}>5</span>
              <div className={ratingStyles.barBackground}>
                <div className={ratingStyles.bar} style={{ width: (values.application?.five_star || 0) + '%' }}></div>
              </div>
            </div>
            <div className={ratingStyles.barContainer}>
              <span className={ratingStyles.number}>4</span>
              <div className={ratingStyles.barBackground}>
                <div className={ratingStyles.bar} style={{ width: (values.application?.four_star || 0) + '%' }}></div>
              </div>
            </div>
            <div className={ratingStyles.barContainer}>
              <span className={ratingStyles.number}>3</span>
              <div className={ratingStyles.barBackground}>
                <div className={ratingStyles.bar} style={{ width: (values.application?.three_star || 0) + '%' }}></div>
              </div>
            </div>
            <div className={ratingStyles.barContainer}>
              <span className={ratingStyles.number}>2</span>
              <div className={ratingStyles.barBackground}>
                <div className={ratingStyles.bar} style={{ width: (values.application?.two_star || 0) + '%' }}></div>
              </div>
            </div>
            <div className={ratingStyles.barContainer}>
              <span className={ratingStyles.number}>1</span>
              <div className={ratingStyles.barBackground}>
                <div className={ratingStyles.bar} style={{ width: (values.application?.one_star || 0) + '%' }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className={commentsStyles.comment}>
        {/*<!--   Comment start     -->*/}
        {values.comments.map((comment, index) => {
          return (
            <div key={index}>
              <div className={commentsStyles.comment}>
                <div className={commentsStyles.comment_top}>
                  <div className={commentsStyles.comment_title}>
                    <img
                      src={comment.avatar || commentAvatarPlaceholder}
                      alt="author avatar"
                      className={commentsStyles.comment_avatar}
                    />
                    <div className={commentsStyles.comment_author}>{comment.author}</div>
                  </div>
                  <div>
                    <i className={`material-icons ${indexStyles.md24} ${indexStyles.vert}`}>more_vert</i>
                  </div>
                </div>
                <div className={commentsStyles.comment_ratingContainer}>
                  {/*<!-- `rect width` should be calculated as width = rating / 5 * 100% -->*/}
                  {/*<!-- example width = 4 / 5 * 100% = 80% -->*/}
                  {/*<!-- we change only 2nd param -->*/}
                  <div className={commentsStyles.activeStars}
                       style={{ clipPath: `rect(0 ${comment.rating / 5 * 100}% 100% 0)` }}>
                    <img src={commentStar} alt="review star"/>
                    <img src={commentStar} alt="review star"/>
                    <img src={commentStar} alt="review star"/>
                    <img src={commentStar} alt="review star"/>
                    <img src={commentStar} alt="review star"/>
                  </div>
                  <div className={commentsStyles.inactiveStars}>
                    <img src={commentStarEmpty} alt="review star"/>
                    <img src={commentStarEmpty} alt="review star"/>
                    <img src={commentStarEmpty} alt="review star"/>
                    <img src={commentStarEmpty} alt="review star"/>
                    <img src={commentStarEmpty} alt="review star"/>
                  </div>
                  <div className={commentsStyles.comment_date}>{transformDate(comment.date)}</div>
                </div>
                <div className={commentsStyles.comment_text}>{comment.text}</div>
                <div className={commentsStyles.comment_helpfulText}>190 {values.application.review_helpful}</div>
                <div className={commentsStyles.comment_helpfulContainer}>
                  <div className={commentsStyles.comment_question}>{values.application.find_this_helpful}</div>
                  <div className={commentsStyles.comment_btnsContainer}>
                    <button type="button" className={commentsStyles.btn}>{values.application.helpful_yes}</button>
                    <button type="button" className={commentsStyles.btn}>{values.application.helpful_no}</button>
                  </div>
                </div>
              </div>
            </div>
          )

        })}
        {/*<!--   Comment end     -->*/}

        <div className={commentsStyles.comment_allReviews}>{values.application.all_reviews_text}</div>
      </div>

      <div className={whatsNewStyles.whatsNew}>
        <h2>{values.application.whats_new_text}</h2>
        <p>{values.application.whats_new}</p>

        <div className={whatsNewStyles.developers}>
          <h2>{values.application.developer_contact}</h2>
          <div className={`material-icons ${indexStyles.md24} ${whatsNewStyles.icon}`}
               id="developerContactButton">expand_more
          </div>
        </div>

        <div id="developerContactContainer" style={{ display: 'none' }}>
          <div className={whatsNewStyles.developerContactContainer}>
            <div className={`material-icons ${indexStyles.md18} ${whatsNewStyles.emailIcon}`}>email</div>
            <div>
              <div className={whatsNewStyles.email}>Email</div>
              <div className={whatsNewStyles.address}> perfectfruitcasino@au.com</div>
            </div>
          </div>
        </div>

        <div className={whatsNewStyles.footerLine}></div>
      </div>
    </div>
  );
};

