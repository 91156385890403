import { useFormikContext } from "formik";
import { WebViewFormValues } from "../helpers/formikHelpersWebView";
import styles from "../../../PwaForm/PWAName/PWAName.module.scss";
import FormInput from "../../../../components/FormInput";
import { WebViewPagesNavigation } from "../WebViewPagesNavigation/WebViewPagesNavigation";

export const WebViewName = ()=> {
  const { values, errors } = useFormikContext<WebViewFormValues>();
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <span className={styles.inputItem}>
          <label>Название WebView</label>
          <FormInput
            name="name"
            placeholder="Введите имя"
          />
        </span>
        <WebViewPagesNavigation nexButtonDisabled={
          !values.name || !!errors.name}/>
      </div>
    </div>
  )
}