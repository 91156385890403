import React, { useRef } from "react";
import styles from "../CreatedPWASuccessModal/CreatedPWASuccessModal.module.scss";
import closeIcon from "../../../img/closeIcon.svg";
import { Button } from "../../Button/Button";
import { useCloseModal } from "../hooks";

type Prop = {
  duplicatePwa: () => Promise<unknown>;
  closeModal: () => void;
}

export const DuplicatePWAModal: React.FC<Prop> = ({ duplicatePwa, closeModal }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useCloseModal(modalRef, closeModal);

  return (
    <div className="modal-background">
      <div className={styles.wrapper} ref={modalRef}>
        <div className="modal-titleContainer">
          <div className="modal-title">Дублирование PWA</div>

          <img
            src={closeIcon}
            alt="close icon"
            className="modal-closeIcon"
            onClick={closeModal}/>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.warningInfo}>
            Нажмите “Подтвердить” что бы дублировать PWA
          </div>
        </div>
        <div className="modal-bottomWarapper">
          <Button onClick={async () => {
            await duplicatePwa();
            closeModal();
          }}>Подтвердить</Button>
          <button type="button" onClick={closeModal} className="modal-closeBtn">Закрыть</button>
        </div>

      </div>
    </div>
  )
}