import styles from "./TableTools.module.scss";
import penIcon from "../../img/tabletools/penIcon.svg";
import chartIcon from "../../img/tabletools/chartIcon.svg";
import deleteIcon from "../../img/tabletools/trashBinIcon.svg";
import dollarIcon from "../../img/tabletools/dollarIcon.svg";
import copyIcon from "../../img/tabletools/copy.svg";
import chainIcon from "../../img/tabletools/chain.svg";
import greyChainIcon from "../../img/tabletools/greyChain.svg";
import paymentInProcess from "../../img/tabletools/paymentInProcess.svg";
import circleWarning from "../../img/tabletools/circleWarning.svg";
import { useAppDispatch } from "../../store/hooks";
import { changePWAStatus, duplicatePWAThunk } from "../../store/reducers/pwaListReducer";
import { ListItemData, Status, StartStopState } from "../../types/api";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { ActivatePWAModal } from "../Modals/ActivatePWAModal/ActivatePWAModal";
import { DeletePWAModal } from "../Modals/DeletePWAModal/DeletePWAModal";
import { DuplicatePWAModal } from "../Modals/DuplicateModal/DuplicatePWAModal";
import { LinksModal } from "../Modals/LinksModal/LinksModal";
import { params } from "../../api/helpers/constants";
import { Tooltip } from "@mui/material";
import { ViewType } from "../../types/global";
import { ActivateWebViewModal } from "../Modals/ActivatePWAModal/ActivateWebViewModal";
import { DeleteWebViewModal } from "../Modals/DeletePWAModal/DeleteWebViewModal";
import { DuplicateWebViewModal } from "../Modals/DuplicateModal/DuplicateWebViewModal";
import { duplicateWebViewThunk } from "../../store/reducers/webViewReducer";


type Props = {
  data: ListItemData,
  viewType: ViewType,
}

export const TableTools = ({ data, viewType }: Props) => {
  const dispatch = useAppDispatch();

  const [showActivateModal, setShowActivateModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [showDuplicateModal, setDuplicateModal] = useState(false);
  const [showChainModal, setChainModal] = useState(false);

  return (
    <div className={styles.tableTools}>
      {((data.status === Status.work || data.status === Status.stop) && viewType==="pwa" ) && <Tooltip title="Статистика">
        <button type="button" onClick={() => {
          dispatch(changePWAStatus({
            pwa_id: data.id,
            state: data.status === Status.work ? StartStopState.stop : StartStopState.start,
          }))
        }}>
          <Link to={`/statistics/?${params.pwa}=${data.name}`}>
            <img
              src={chartIcon}
              width={24}
              height={24}
              alt="pause Icon"
            />
          </Link>
        </button>
      </Tooltip>}
      {(viewType === "webView" && data.status === Status.work) ? <Tooltip title="Оплачено">
        <div>
          <img src={paymentInProcess} width={24} height={24} alt="dollar Icon"/>
        </div>
        </Tooltip> : ""}
        {data.status === Status.error && <Tooltip title="Ошибка">
          <img src={circleWarning} width={24} height={24} alt="warning Icon"/>
        </Tooltip>}
        {data.status === Status.not_paid && <Tooltip title="Оплатить 6$">
          <button
            type="button"
            onClick={() => setShowActivateModal(true)}>
            <img src={dollarIcon} width={24} height={24} alt="dollar Icon"/>
          </button>
        </Tooltip>}
        {data.status === Status.in_process && <Tooltip title="Оплата в процессе">
          <div>
            <img src={paymentInProcess} width={24} height={24} alt="dollar Icon"/>
          </div>
        </Tooltip>}
        <Tooltip title="Получить ссылки">
          <button type="button" onClick={() => setChainModal(true)}>
            <img src={data.domain ? chainIcon : greyChainIcon} width={24} height={24} alt="chain Icon"
                 className={styles.chainIcon}/>
          </button>
        </Tooltip>
        <Tooltip title="Редактировать">
          <Link to={viewType === "pwa" ? `/edit/${data.id}` : `/edit-web-view/${data.id}`}>
            <img src={penIcon} width={24} height={24} alt="edit Icon"/>
          </Link>
        </Tooltip>
        <Tooltip title="Дублировать">
          <button
            type="button"
            onClick={() => setDuplicateModal(true)}
          ><img src={copyIcon} width={24} height={24} alt="copy Icon"/></button>
        </Tooltip>
        <Tooltip title="Удалить">
          <button
            type="button"
            onClick={() => {
              setDeleteModal(true)
            }}
          >
            <img src={deleteIcon} width={24} height={24} alt="delete Icon"/>
          </button>
        </Tooltip>
        {showActivateModal && (
          viewType === "pwa" ? <ActivatePWAModal
            hideModal={() => setShowActivateModal(false)}
            pwaId={data.id}
          /> : <ActivateWebViewModal hideModal={() => setShowActivateModal(false)} webviewId={data.id}/>)
        }

        {showDeleteModal &&
          (viewType === "pwa" ? <DeletePWAModal
            hideModal={() => setDeleteModal(false)}
            pwaId={data.id}
          /> : <DeleteWebViewModal
            hideModal={() => setDeleteModal(false)}
            webviewId={data.id}
          />)


        }
        {showDuplicateModal && (
          viewType === "pwa" ? <DuplicatePWAModal
              duplicatePwa={async () => dispatch(duplicatePWAThunk(data.id))}
              closeModal={() => setDuplicateModal(false)}
            /> :
            <DuplicateWebViewModal
              duplicateWebView={async () => dispatch(duplicateWebViewThunk(data.id))}
              closeModal={() => setDuplicateModal(false)}
            />
        )
        }
        {
          showChainModal && data.domain
          && <LinksModal domain={data.domain} hideModal={() => setChainModal(false)}/>
        }
      </div>
        )
      }